@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import "~vue-snotify/styles/material.css";
@import url("https://fonts.googleapis.com/css?family=Fredericka+the+Great&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
/*
TO UPDATE THE FONT

1. Go to: fontello.com
2. Import ../assets/hk-icon-font/harmless-key.svg
3. Add svg's of the icons you wish to add
4. Download the updated font
5. Replace the files in ../assets/hk-icon-font with the files from /font folder in you download
6. Add the classes to the CSS below (don't replace the existing CSS below, it's modified for HK)

*/
@font-face {
  font-family: "harmless-key, sans-serif";
  src: url("../assets/hk-icon-font/harmless-key.eot?29559614");
  src: url("../assets/hk-icon-font/harmless-key.eot?29559614#iefix") format("embedded-opentype"), url("../assets/hk-icon-font/harmless-key.woff2?29559614") format("woff2"), url("../assets/hk-icon-font/harmless-key.woff?29559614") format("woff"), url("../assets/hk-icon-font/harmless-key.ttf?29559614") format("truetype"), url("../assets/hk-icon-font/harmless-key.svg?29559614#harmless-key") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=hki-]:before,
[class*=" hki-"]:before {
  font-family: "harmless-key, sans-serif";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* you can be more comfortable with increased icons size */
  font-size: 120%;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hki {
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
}
.hki-dragon:before, .hki-monster:before {
  content: "";
}
.hki-spiked-wall:before, .hki-environment:before {
  content: "";
}
.hki-axe:before {
  content: "";
}
.hki-hooded:before, .hki-player:before {
  content: "";
}
.hki-wolf:before, .hki-companion:before {
  content: "";
}
.hki-sword-break:before {
  content: "";
}
.hki-swords-clash:before {
  content: "";
}
.hki-heal:before {
  content: "";
}
.hki-incapacitated:before, .hki-stunned:before {
  content: "";
}
.hki-invisible:before {
  content: "";
}
.hki-surprised:before {
  content: "";
}
.hki-paralyzed:before {
  content: "";
}
.hki-petrified:before {
  content: "";
}
.hki-poisoned:before {
  content: "";
}
.hki-restrained:before {
  content: "";
}
.hki-unconscious:before {
  content: "";
}
.hki-blinded:before {
  content: "";
}
.hki-charmed:before {
  content: "";
}
.hki-deafened:before {
  content: "";
}
.hki-exhaustion:before, .hki-prone:before {
  content: "";
}
.hki-frightened:before {
  content: "";
}
.hki-grappled:before {
  content: "";
}

.splitpanes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}

.splitpanes--vertical {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.splitpanes--horizontal {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.splitpanes--dragging * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splitpanes__pane {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.splitpanes--vertical .splitpanes__pane {
  -webkit-transition: width 0.2s ease-out;
  -o-transition: width 0.2s ease-out;
  transition: width 0.2s ease-out;
}

.splitpanes--horizontal .splitpanes__pane {
  -webkit-transition: height 0.2s ease-out;
  -o-transition: height 0.2s ease-out;
  transition: height 0.2s ease-out;
}

.splitpanes--dragging .splitpanes__pane {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.splitpanes__splitter {
  -ms-touch-action: none;
  touch-action: none;
}

.splitpanes--vertical > .splitpanes__splitter {
  min-width: 1px;
  cursor: col-resize;
}

.splitpanes--horizontal > .splitpanes__splitter {
  min-height: 1px;
  cursor: row-resize;
}

.splitpanes.default-theme .splitpanes__pane {
  background-color: var(--neutral-6-transparent);
}
.splitpanes.default-theme .splitpanes__pane:has(.splitpanes) {
  background: none;
}

.splitpanes.default-theme .splitpanes__splitter {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.splitpanes.default-theme .splitpanes__splitter:before,
.splitpanes.default-theme .splitpanes__splitter:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ffffff81;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.splitpanes.default-theme .splitpanes__splitter:hover:before,
.splitpanes.default-theme .splitpanes__splitter:hover:after {
  background-color: #ffffff81;
}

.splitpanes.default-theme .splitpanes__splitter:first-child {
  cursor: auto;
}

.default-theme.splitpanes .splitpanes .splitpanes__splitter {
  z-index: 1;
}

.default-theme.splitpanes--vertical > .splitpanes__splitter,
.default-theme .splitpanes--vertical > .splitpanes__splitter {
  width: 7px;
  margin-left: -1px;
}

.default-theme.splitpanes--vertical > .splitpanes__splitter:before,
.default-theme.splitpanes--vertical > .splitpanes__splitter:after,
.default-theme .splitpanes--vertical > .splitpanes__splitter:before,
.default-theme .splitpanes--vertical > .splitpanes__splitter:after {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 30px;
}

.default-theme.splitpanes--vertical > .splitpanes__splitter:before,
.default-theme .splitpanes--vertical > .splitpanes__splitter:before {
  margin-left: -2px;
}

.default-theme.splitpanes--vertical > .splitpanes__splitter:after,
.default-theme .splitpanes--vertical > .splitpanes__splitter:after {
  margin-left: 1px;
}

.default-theme.splitpanes--horizontal > .splitpanes__splitter,
.default-theme .splitpanes--horizontal > .splitpanes__splitter {
  height: 7px;
  margin-top: -1px;
}

.default-theme.splitpanes--horizontal > .splitpanes__splitter:before,
.default-theme.splitpanes--horizontal > .splitpanes__splitter:after,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:before,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:after {
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translate(-50%);
  width: 30px;
  height: 1px;
}

.default-theme.splitpanes--horizontal > .splitpanes__splitter:before,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:before {
  margin-top: -2px;
}

.default-theme.splitpanes--horizontal > .splitpanes__splitter:after,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:after {
  margin-top: 1px;
}

/* 2. Themes */
/*********************************************/
[data-theme=light] body {
  background: var(--neutral-10);
}
[data-theme=light] body .btn {
  color: var(--neutral-11);
}
[data-theme=light] body .btn-clear {
  color: var(--neutral-2);
}
[data-theme=light] body .btn-clear:hover {
  color: var(--neutral-11);
}
[data-theme=light] body .btn.bg-neutral-5, [data-theme=light] body .btn.bg-neutral-6, [data-theme=light] body .btn.bg-neutral-8, [data-theme=light] body .btn.bg-neutral-9 {
  color: var(--neutral-2);
}
[data-theme=light] body .live {
  color: var(--neutral-2);
}
[data-theme=light] body .live::before {
  background-color: var(--neutral-3);
}
[data-theme=light] body .live.active::before {
  background-color: var(--neutral-3);
}
[data-theme=light] body ul.entities .img {
  background-color: var(--neutral-2);
  color: var(--neutral-8);
}
[data-theme=light] body .hk-table-column .image {
  background-color: var(--neutral-2);
  color: var(--neutral-8);
}
[data-theme=light] body .target-item .img {
  color: var(--neutral-8);
  background-color: var(--neutral-2);
}
[data-theme=light] body .target-item .ac_wrapper i {
  color: var(--neutral-2);
}
[data-theme=light] body .target-item .ac_wrapper .ac {
  color: var(--neutral-11);
}
[data-theme=light] body .google {
  background-color: var(--white);
  color: var(--neutral-4);
  border-color: transparent;
  box-shadow: 1px 1px 1px var(--neutral-8);
}

/*
	Table of content
	1. Variables
	2. Defaults
	3. Headings
	4. Classes
	5. Links
	6. Bootstrap overrides
	7. Snotify overrides
	8. Custom scrollbars
	9. Number field
	10. Croppa
*/
/* 2. Defaults */
/*********************************************/
html body {
  background: var(--neutral-11);
  color: var(--neutral-1);
  font-size: var(--font-size);
  font-family: "Open Sans", sans-serif;
  position: relative;
  /* 3. Heading */
  /*********************************************/
  /* 4. Classes */
  /*********************************************/
  /* 4. Animations */
  /*********************************************/
  /* 4. Buttons and Links */
  /*********************************************/
  /* 5. Components*/
  /*********************************************/
  /* ACCORDION */
  /* HK CARD */
  /* HK CARD DECK */
  /* HK ROLLS */
  /* HK TABLE */
  /* HK POPOVER */
  /* 7. Snotify overrides */
  /*********************************************/
  /* 9. Number field */
  /*********************************************/
  /* 10. CROPPA */
  /*********************************************/
  /* 11. QUASAR */
  /*********************************************/
  /*-----------------------------------*/
}
html body button {
  all: unset;
  text-align: center;
  box-sizing: border-box;
}
html body *:focus-visible {
  outline-offset: 3px;
}
html body::-webkit-scrollbar {
  display: none;
}
html body .container-fluid {
  padding: 0;
}
html body .hasSide {
  display: grid;
  grid-template-columns: max-content auto;
}
html body #header {
  display: block;
  height: var(--header-height);
  line-height: 50px;
  width: 100%;
  background: var(--neutral-6);
  border-bottom: solid 1px transparent;
  position: fixed;
  top: 0;
  z-index: 999;
  padding: 0 10px 0 5px;
}
html body pre {
  color: var(--neutral-2);
}
html body .text-uppercase {
  text-transform: uppercase;
}
html body .text-capitalize {
  text-transform: capitalize;
}
html body .text-lowercase {
  text-transform: lowercase;
}
html body .text-shadow {
  text-shadow: 0 0 5px var(--black);
}
html body .text-shadow-3 {
  text-shadow: 0 0 3px var(--black);
}
html body .text-shadow-4 {
  text-shadow: 0 0 4px var(--black);
}
html body .text-shadow-5 {
  text-shadow: 0 0 5px var(--black);
}
html body .text-shadow-6 {
  text-shadow: 0 0 6px var(--black);
}
html body .text-shadow-7 {
  text-shadow: 0 0 7px var(--black);
}
html body .text-shadow-8 {
  text-shadow: 0 0 8px var(--black);
}
html body h1,
html body h2,
html body h3,
html body h4,
html body h5,
html body h6 {
  font-weight: bold;
  margin: 0;
}
html body h1 {
  font-size: 25px;
  line-height: 40px;
  margin-bottom: 15px;
  color: var(--neutral-1);
}
html body h2 {
  font-size: 20px;
  line-height: 35px;
  margin-bottom: 20px;
}
html body h3 {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 33px;
}
html body h4 {
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 30px;
}
html body .white {
  color: var(--white) !important;
}
html body .text-left {
  text-align: left !important;
}
html body .neutral-1,
html body .text-neutral-1 {
  color: var(--neutral-1) !important;
}
html body .neutral-1 path,
html body .text-neutral-1 path {
  fill: var(--neutral-1) !important;
}
html body .neutral-2,
html body .text-neutral-2 {
  color: var(--neutral-2) !important;
}
html body .neutral-2 path,
html body .text-neutral-2 path {
  fill: var(--neutral-2) !important;
}
html body .neutral-3,
html body .text-neutral-3 {
  color: var(--neutral-3) !important;
}
html body .neutral-3 path,
html body .text-neutral-3 path {
  fill: var(--neutral-3) !important;
}
html body .neutral-4,
html body .text-neutral-4 {
  color: var(--neutral-4) !important;
}
html body .neutral-4 path,
html body .text-neutral-4 path {
  fill: var(--neutral-4) !important;
}
html body .neutral-5,
html body .text-neutral-5 {
  color: var(--neutral-5) !important;
}
html body .neutral-5 path,
html body .text-neutral-5 path {
  fill: var(--neutral-5) !important;
}
html body .neutral-6,
html body .text-neutral-6 {
  color: var(--neutral-6) !important;
}
html body .neutral-6 path,
html body .text-neutral-6 path {
  fill: var(--neutral-6) !important;
}
html body .neutral-7,
html body .text-neutral-7 {
  color: var(--neutral-7) !important;
}
html body .neutral-7 path,
html body .text-neutral-7 path {
  fill: var(--neutral-7) !important;
}
html body .neutral-8,
html body .text-neutral-8 {
  color: var(--neutral-8) !important;
}
html body .neutral-8 path,
html body .text-neutral-8 path {
  fill: var(--neutral-8) !important;
}
html body .neutral-9,
html body .text-neutral-9 {
  color: var(--neutral-9) !important;
}
html body .neutral-9 path,
html body .text-neutral-9 path {
  fill: var(--neutral-9) !important;
}
html body .neutral-10,
html body .text-neutral-10 {
  color: var(--neutral-10) !important;
}
html body .neutral-10 path,
html body .text-neutral-10 path {
  fill: var(--neutral-10) !important;
}
html body .neutral-11,
html body .text-neutral-11 {
  color: var(--neutral-11) !important;
}
html body .neutral-11 path,
html body .text-neutral-11 path {
  fill: var(--neutral-11) !important;
}
html body .green {
  color: var(--green) !important;
}
html body .green path {
  fill: var(--green) !important;
}
html body .blue {
  color: var(--blue) !important;
}
html body .blue path {
  fill: var(--blue) !important;
}
html body .red {
  color: var(--red) !important;
}
html body .red path {
  fill: var(--red) !important;
}
html body .black {
  color: var(--black) !important;
}
html body .black path {
  fill: var(--black) !important;
}
html body .patreon-red {
  color: var(--patreon-red) !important;
}
html body .patreon-red path {
  fill: var(--patreon-red) !important;
}
html body .discord-purple {
  color: var(--discord-purple) !important;
}
html body .discord-purple path {
  fill: var(--discord-purple) !important;
}
html body .orange {
  color: var(--orange) !important;
}
html body .orange path {
  fill: var(--orange) !important;
}
html body .yellow {
  color: var(--yellow) !important;
}
html body .yellow path {
  fill: var(--yellow) !important;
}
html body .purple path {
  fill: var(--purple) !important;
}
html body .blue-light {
  color: var(--blue-light) !important;
}
html body .blue-light path {
  fill: var(--blue-light) !important;
}
html body .facebook-blue {
  color: var(--facebook-blue) !important;
}
html body .facebook-blue path {
  fill: var(--facebook-blue) !important;
}
html body .green-light {
  color: var(--green-light) !important;
}
html body .green-light path {
  fill: var(--green-light) !important;
}
html body .orange-light {
  color: var(--orange-light) !important;
}
html body .orange-light path {
  fill: var(--orange-light) !important;
}
html body .red-light {
  color: var(--red-light) !important;
}
html body .red-light path {
  fill: var(--red-light) !important;
}
html body .yellow-light {
  color: var(--yellow-light) !important;
}
html body .yellow-light path {
  fill: var(--yellow-light) !important;
}
html body .yellow-dark,
html body .text-yellow-dark {
  color: var(--yellow-dark) !important;
}
html body .yellow-dark path,
html body .text-yellow-dark path {
  fill: var(--yellow-dark) !important;
}
html body .copper {
  color: var(--copper) !important;
  fill: var(--copper) !important;
}
html body .silver {
  color: var(--silver) !important;
  fill: var(--silver) !important;
}
html body .electrum {
  color: var(--electrum) !important;
  fill: var(--electrum) !important;
}
html body .gold {
  color: var(--gold) !important;
  fill: var(--gold) !important;
}
html body .platinum {
  color: var(--platinum) !important;
  fill: var(--platinum) !important;
}
html body .bg-neutral-1 {
  background-color: var(--neutral-1) !important;
}
html body .bg-neutral-2 {
  background-color: var(--neutral-2) !important;
}
html body .bg-neutral-3 {
  background-color: var(--neutral-3) !important;
}
html body .bg-neutral-4 {
  background-color: var(--neutral-4) !important;
}
html body .bg-neutral-5 {
  background-color: var(--neutral-5) !important;
}
html body .bg-neutral-6 {
  background-color: var(--neutral-6) !important;
}
html body .bg-neutral-6-transparent {
  background-color: var(--neutral-6-transparent) !important;
}
html body .bg-neutral-7 {
  background-color: var(--neutral-7) !important;
}
html body .bg-neutral-8 {
  background-color: var(--neutral-8) !important;
}
html body .bg-neutral-9 {
  background-color: var(--neutral-9) !important;
}
html body .bg-neutral-10 {
  background-color: var(--neutral-10) !important;
}
html body .bg-neutral-11 {
  background-color: var(--neutral-11) !important;
}
html body .bg-white {
  background-color: var(--white) !important;
}
html body .bg-black {
  background-color: var(--black) !important;
}
html body .bg-none {
  background: none !important;
}
html body .bg-green {
  background-color: var(--green) !important;
}
html body .bg-blue {
  background-color: var(--blue) !important;
}
html body .bg-red {
  background-color: var(--red) !important;
}
html body .bg-patreon-red {
  background-color: var(--patreon-red) !important;
}
html body .bg-orange {
  background-color: var(--orange) !important;
}
html body .bg-yellow {
  background-color: var(--yellow) !important;
}
html body .bg-purple {
  background-color: var(--purple) !important;
}
html body .bg-discord-purple {
  background-color: var(--discord-purple) !important;
}
html body .bg-blue-light {
  background-color: var(--blue-light) !important;
}
html body .bg-green-light {
  background-color: var(--green-light) !important;
}
html body .bg-orange-light {
  background-color: var(--orange-light) !important;
}
html body .bg-red-light {
  background-color: var(--red-light) !important;
}
html body .bg-yellow-light {
  background-color: var(--yellow-light) !important;
}
html body .bg-accent {
  background-color: var(--accent) !important;
}
html body .bg-accent-light {
  background-color: var(--accent-light) !important;
}
html body .healing {
  color: var(--green);
}
html body .acid {
  color: var(--acid);
}
html body .bludgeoning {
  color: var(--bludgeoning);
}
html body .cold {
  color: var(--cold);
}
html body .fire {
  color: var(--fire);
}
html body .force {
  color: var(--force);
}
html body .lightning {
  color: var(--lightning);
}
html body .necrotic {
  color: var(--necrotic);
}
html body .piercing {
  color: var(--piercing);
}
html body .poison {
  color: var(--poison);
}
html body .psychic {
  color: var(--psychic);
}
html body .radiant {
  color: var(--radiant);
}
html body .slashing {
  color: var(--slashing);
}
html body .thunder {
  color: var(--thunder);
}
html body .non_magical_bludgeoning {
  color: var(--bludgeoning);
}
html body .non_magical_piercing {
  color: var(--piercing);
}
html body .non_magical_slashing {
  color: var(--slashing);
}
html body .border-radius {
  border-radius: var(--border-radius);
}
html body .border-radius-small {
  border-radius: var(--border-radius-small);
}
html body .written {
  font-family: "Fredericka the Great", cursive;
}
html body .overflow-hidden {
  overflow: hidden;
}
html body .overflow-auto {
  overflow: auto;
}
html body .overflow-scroll {
  overflow: scroll;
}
html body .overflow-x-hidden {
  overflow-x: hidden;
}
html body .overflow-x-auto {
  overflow-x: auto;
}
html body .overflow-x-scroll {
  overflow-x: scroll;
}
html body .overflow-y-hidden {
  overflow-y: hidden;
}
html body .overflow-y-auto {
  overflow-y: auto;
}
html body .overflow-y-scroll {
  overflow-y: scroll;
}
html body .absolute {
  position: absolute;
}
html body .static {
  position: static;
}
html body .fixed {
  position: fixed;
}
html body .sticky {
  position: sticky;
}
html body .m-0 {
  margin: 0 !important;
}
html body .m-1 {
  margin: 0.25rem !important;
}
html body .m-2 {
  margin: 0.5rem !important;
}
html body .m-3 {
  margin: 1rem !important;
}
html body .m-4 {
  margin: 1.5rem !important;
}
html body .m-5 {
  margin: 3rem !important;
}
html body .mb-0, html body .my-0 {
  margin-bottom: 0 !important;
}
html body .mb-1, html body .my-1 {
  margin-bottom: 0.25rem !important;
}
html body .mb-2, html body .my-2 {
  margin-bottom: 0.5rem !important;
}
html body .mb-3, html body .my-3 {
  margin-bottom: 1rem !important;
}
html body .mb-4, html body .my-4 {
  margin-bottom: 1.5rem !important;
}
html body .mb-5, html body .my-5 {
  margin-bottom: 3rem !important;
}
html body .mt-0, html body .my-0 {
  margin-top: 0 !important;
}
html body .mt-1, html body .my-1 {
  margin-top: 0.25rem !important;
}
html body .mt-2, html body .my-2 {
  margin-top: 0.5rem !important;
}
html body .mt-3, html body .my-3 {
  margin-top: 1rem !important;
}
html body .mt-4, html body .my-4 {
  margin-top: 1.5rem !important;
}
html body .mt-5, html body .my-5 {
  margin-top: 3rem !important;
}
html body .ml-0, html body .mx-0 {
  margin-left: 0 !important;
}
html body .ml-1, html body .mx-1 {
  margin-left: 0.25rem !important;
}
html body .ml-2, html body .mx-2 {
  margin-left: 0.5rem !important;
}
html body .ml-3, html body .mx-3 {
  margin-left: 1rem !important;
}
html body .ml-4, html body .mx-4 {
  margin-left: 1.5rem !important;
}
html body .ml-5, html body .mx-5 {
  margin-left: 3rem !important;
}
html body .mr-0, html body .mx-0 {
  margin-right: 0 !important;
}
html body .mr-1, html body .mx-1 {
  margin-right: 0.25rem !important;
}
html body .mr-2, html body .mx-2 {
  margin-right: 0.5rem !important;
}
html body .mr-3, html body .mx-3 {
  margin-right: 1rem !important;
}
html body .mr-4, html body .mx-4 {
  margin-right: 1.5rem !important;
}
html body .mr-5, html body .mx-5 {
  margin-right: 3rem !important;
}
html body .-m-1 {
  margin: -0.25rem !important;
}
html body .-m-2 {
  margin: -0.5rem !important;
}
html body .-m-3 {
  margin: -1rem !important;
}
html body .-m-4 {
  margin: -1.5rem !important;
}
html body .-m-5 {
  margin: -3rem !important;
}
html body .-mb-1, html body .-my-1 {
  margin-bottom: -0.25rem !important;
}
html body .-mb-2, html body .-my-2 {
  margin-bottom: -0.5rem !important;
}
html body .-mb-3, html body .-my-3 {
  margin-bottom: -1rem !important;
}
html body .-mb-4, html body .-my-4 {
  margin-bottom: -1.5rem !important;
}
html body .-mb-5, html body .-my-5 {
  margin-bottom: -3rem !important;
}
html body .-mt-1, html body .-my-1 {
  margin-top: -0.25rem !important;
}
html body .-mt-2, html body .-my-2 {
  margin-top: -0.5rem !important;
}
html body .-mt-3, html body .-my-3 {
  margin-top: -1rem !important;
}
html body .-mt-4, html body .-my-4 {
  margin-top: -1.5rem !important;
}
html body .-mt-5, html body .-my-5 {
  margin-top: -3rem !important;
}
html body .-ml-1, html body .-mx-1 {
  margin-left: -0.25rem !important;
}
html body .-ml-2, html body .-mx-2 {
  margin-left: -0.5rem !important;
}
html body .-ml-3, html body .-mx-3 {
  margin-left: -1rem !important;
}
html body .-ml-4, html body .-mx-4 {
  margin-left: -1.5rem !important;
}
html body .-ml-5, html body .-mx-5 {
  margin-left: -3rem !important;
}
html body .-mr-1, html body .-mx-1 {
  margin-right: -0.25rem !important;
}
html body .-mr-2, html body .-mx-2 {
  margin-right: -0.5rem !important;
}
html body .-mr-3, html body .-mx-3 {
  margin-right: -1rem !important;
}
html body .-mr-4, html body .-mx-4 {
  margin-right: -1.5rem !important;
}
html body .-mr-5, html body .-mx-5 {
  margin-right: -3rem !important;
}
html body .p-0 {
  padding: 0 !important;
}
html body .p-1 {
  padding: 0.25rem !important;
}
html body .p-2 {
  padding: 0.5rem !important;
}
html body .p-3 {
  padding: 1rem !important;
}
html body .p-4 {
  padding: 1.5rem !important;
}
html body .p-5 {
  padding: 3rem !important;
}
html body .pb-0, html body .py-0 {
  padding-bottom: 0 !important;
}
html body .pb-1, html body .py-1 {
  padding-bottom: 0.25rem !important;
}
html body .pb-2, html body .py-2 {
  padding-bottom: 0.5rem !important;
}
html body .pb-3, html body .py-3 {
  padding-bottom: 1rem !important;
}
html body .pb-4, html body .py-4 {
  padding-bottom: 1.5rem !important;
}
html body .pb-5, html body .py-5 {
  padding-bottom: 3rem !important;
}
html body .pt-0, html body .py-0 {
  padding-top: 0 !important;
}
html body .pt-1, html body .py-1 {
  padding-top: 0.25rem !important;
}
html body .pt-2, html body .py-2 {
  padding-top: 0.5rem !important;
}
html body .pt-3, html body .py-3 {
  padding-top: 1rem !important;
}
html body .pt-4, html body .py-4 {
  padding-top: 1.5rem !important;
}
html body .pt-5, html body .py-5 {
  padding-top: 3rem !important;
}
html body .pl-0, html body .px-0 {
  padding-left: 0 !important;
}
html body .pl-1, html body .px-1 {
  padding-left: 0.25rem !important;
}
html body .pl-2, html body .px-2 {
  padding-left: 0.5rem !important;
}
html body .pl-3, html body .px-3 {
  padding-left: 1rem !important;
}
html body .pl-4, html body .px-4 {
  padding-left: 1.5rem !important;
}
html body .pl-5, html body .px-5 {
  padding-left: 3rem !important;
}
html body .pr-0, html body .px-0 {
  padding-right: 0 !important;
}
html body .pr-1, html body .px-1 {
  padding-right: 0.25rem !important;
}
html body .pr-2, html body .px-2 {
  padding-right: 0.5rem !important;
}
html body .pr-3, html body .px-3 {
  padding-right: 1rem !important;
}
html body .pr-4, html body .px-4 {
  padding-right: 1.5rem !important;
}
html body .pr-5, html body .px-5 {
  padding-right: 3rem !important;
}
html body .d-flex {
  display: flex;
}
html body .d-none {
  display: none;
}
html body .d-block {
  display: block;
}
html body .d-table-cell {
  display: table-cell;
}
html body .d-inline {
  display: inline;
}
html body .d-inline-block {
  display: inline-block;
}
html body .flex-col {
  flex-direction: column;
}
html body .flex-row {
  flex-direction: row;
}
html body .flex-grow {
  flex-grow: 1;
}
html body .gap-1 {
  gap: 0.5rem;
}
html body .gap-2 {
  gap: 1rem;
}
html body .gap-3 {
  gap: 1.5rem;
}
html body .gap-4 {
  gap: 2rem;
}
html body .gap-x-1 {
  column-gap: 0.5rem;
}
html body .gap-x-2 {
  column-gap: 1rem;
}
html body .gap-x-3 {
  column-gap: 1.5rem;
}
html body .gap-x-4 {
  column-gap: 2rem;
}
html body .gap-y-1 {
  row-gap: 0.5rem;
}
html body .gap-y-2 {
  row-gap: 1rem;
}
html body .gap-y-3 {
  row-gap: 1.5rem;
}
html body .gap-y-4 {
  row-gap: 2rem;
}
html body .justify-content-between {
  justify-content: space-between;
}
html body .justify-content-evenly {
  justify-content: space-evenly;
}
html body .justify-content-center {
  justify-content: center;
}
html body .justify-content-start {
  justify-content: flex-start !important;
}
html body .justify-content-end {
  justify-content: flex-end;
}
html body .items-start {
  align-items: start !important;
}
html body .items-baseline {
  align-items: baseline !important;
}
html body .items-center {
  align-items: center !important;
}
html body .items-end {
  align-items: end !important;
}
html body .whitespace-normal {
  white-space: normal;
}
html body .whitespace-nowrap {
  white-space: nowrap;
}
html body .whitespace-pre {
  white-space: pre;
}
html body .whitespace-pre-line {
  white-space: pre-line;
}
html body .whitespace-pre-wrap {
  white-space: pre-wrap;
}
html body .step-highlight {
  outline: var(--yellow-light) solid 2px;
  outline-offset: 2px;
}
html body .hk-layout {
  min-height: calc(100vh - var(--header-height));
  display: grid;
  grid-template-rows: 1fr max-content;
  grid-template-columns: 100%;
}
html body .hk-layout .hk-ad {
  background-color: var(--neutral-10);
}
html body .hk-layout .hk-ad .card-body {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
html body .hk-layout .hk-ad .card-body img {
  margin: 0 15px 0 -5px;
  width: 80px;
}
html body .hk-layout .hk-ad .card-body .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
html body .hk-layout .hk-ad .card-body .right .title {
  font-weight: bold;
  font-size: 20px;
}
html body .breadcrumb {
  margin-bottom: 1rem;
  background: var(--neutral-6);
  border-radius: var(--border-radius);
  padding: 10px 15px;
}
html body .offline {
  position: fixed;
  top: 50px;
  max-width: 350px;
  background: var(--red);
  color: var(--neutral-1);
  z-index: 99;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px;
  font-size: 12px;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
html body .saved {
  animation: fadeout 0.3s linear;
  animation-delay: 2s;
}
html body .flip-clock .flip-card__top, html body .flip-clock .flip-card__bottom, html body .flip-clock .flip-card__back, html body .flip-clock .flip-card__back-bottom {
  color: var(--blue) !important;
}
html body .flip-clock .flip-card__top:before, html body .flip-clock .flip-card__bottom:before, html body .flip-clock .flip-card__back:before, html body .flip-clock .flip-card__back-bottom:before {
  color: var(--blue) !important;
}
html body .disabled {
  cursor: forbidden !important;
  opacity: 0.5 !important;
}
html body .content-count {
  background-color: var(--neutral-9);
  padding: 5px 10px;
  border-radius: var(--border-radius);
  user-select: none;
  margin-right: 4px;
}
html body .content-count .divider {
  color: var(--neutral-2);
  margin: 0 4px;
}
html body .invert {
  filter: invert(1);
}
html body .pointer {
  cursor: pointer !important;
}
html body .cursor-pointer {
  cursor: pointer !important;
}
html body .cursor-default {
  cursor: default !important;
}
html body .cursor-not-allowed {
  cursor: not-allowed !important;
}
html body .truncate-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}
html body .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
html body .scroll::-webkit-scrollbar {
  display: none !important;
}
html body .q-card.install-prompt {
  color: var(--neutral-10);
  max-width: 300px;
  align-items: top;
}
html body .q-card.install-prompt .logo {
  align-self: top;
  width: 50px;
  margin-right: 10px;
}
html body .q-card.install-prompt .logo img {
  width: 50px;
}
html body .q-card.install-prompt p {
  margin-bottom: 10px;
}
html body .q-card.install-prompt .play-store {
  width: 130px;
}
html body .q-card.install-prompt h4 {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
}
html body .q-card.install-prompt .q-checkbox {
  margin-left: -7px;
}
html body .openSlot {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
  height: 46px;
  line-height: 46px;
  margin-top: 1px;
  border: dashed 1px var(--neutral-2);
}
html body .openSlot.none {
  font-size: 20px;
  display: block;
  text-align: center;
  border-color: transparent;
  background-color: var(--patreon-red);
}
html body .scrollable-content {
  height: calc(100vh - var(--header-height));
}
html body .scrollable-content::-webkit-scrollbar {
  display: none;
}
html body .scrollable-content .content {
  margin: 0 auto;
  width: 100%;
  max-width: 1250px;
  padding: 20px;
}
html body .scrollable-content .content__edit .save {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: sticky;
  bottom: 5px;
  padding: 10px 10px;
  margin: 5px 5px;
  background: var(--neutral-9);
  border: solid 1px var(--neutral-8);
  border-radius: var(--border-radius);
  flex-direction: row-reverse;
}
html body .scrollable-content .content__edit .save .unsaved_changes {
  margin: 0 10px 0 0;
  line-height: 31px;
}
html body .scrollable-content .content__edit .save .buttons {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 343px) {
  html body .scrollable-content .content__edit .save .buttons {
    margin-bottom: 15px;
  }
}
html body .content-side {
  position: sticky;
  top: 16px;
}
html body .content-side .video .animated-video {
  margin-left: -2%;
  transform: scale(1.8);
}
html body .content-side .video .video-controls {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}
html body .home {
  scroll-behavior: smooth;
}
html body .home .content {
  max-width: 1140px;
  margin: auto;
}
html body .home__container {
  padding: 0 15px;
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
}
html body .home section {
  font-size: 18px;
  line-height: 30px;
}
html body .home section .head {
  margin-bottom: 50px;
}
html body .home section .head h2 {
  font-size: 35px;
  line-height: 45px;
  margin-bottom: 10px;
}
html body .home section .head .text {
  max-width: 600px;
}
html body .home section h3 {
  font-size: 25px !important;
  margin-bottom: 30px !important;
  font-weight: normal;
}
html body .home section p {
  margin-bottom: 50px;
}
html body .home section .next {
  display: block;
  text-align: center;
  margin: 0;
  color: var(--neutral-1) !important;
  position: absolute;
  bottom: 70px;
  left: 50%;
  margin-left: -125px;
  width: 250px;
  font-size: 25px;
}
html body .home section .next i {
  font-size: 35px;
}
html body .home section .next:hover {
  text-decoration: none;
}
html body .home section .next:hover i {
  font-size: 45px;
}
html body .character-description .stat {
  font-weight: bold;
  cursor: default;
}
html body .character-description :last-child {
  margin-bottom: 0;
}
html body .validate {
  margin-top: 5px;
  font-size: 11px;
}
html body .error,
html body .error:focus {
  border-color: var(--red) !important;
}
html body .loader {
  line-height: 25px;
  height: 25px;
  padding-left: 25px;
  color: var(--neutral-2);
}
html body .loader:before {
  content: "";
  background-image: url("../assets/_img/logo/logo-icon-no-shield-cyan.svg");
  background-size: cover;
  box-sizing: border-box;
  position: absolute;
  width: 25px;
  height: 25px;
  margin-left: -30px;
  animation: spinner 1s ease infinite;
}
html body .loader:after {
  display: inline-block;
  animation: ellipsis steps(1, end) 1s infinite;
  content: "";
}
html body .spin {
  animation: spinner 1s ease infinite;
}
html body .rotate {
  animation: spinner 1s ease;
}
html body .live {
  font-family: "Open Sans", sans-serif;
  user-select: none;
  display: inline-block;
  background-color: var(--neutral-5);
  padding: 5px 10px;
  font-size: 12px;
  border-radius: var(--border-radius);
  max-height: 25px;
  line-height: normal;
  text-transform: uppercase;
}
html body .live::before {
  content: "";
  background-color: var(--neutral-1);
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
html body .live:hover {
  background-color: var(--neutral-4);
}
html body .live.active {
  background-color: var(--red);
}
html body .live.active::before {
  background-color: var(--neutral-1);
  animation: fadeinout 1.3s linear infinite;
}
html body .live.active:hover {
  background-color: var(--red-hover);
}
html body .live.disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
}
html body .grow:hover {
  animation-name: grow;
  animation-duration: 0.5s;
}
html body label.required:after {
  content: "*";
  color: var(--red);
  font-size: 25px;
  line-height: 15px;
  vertical-align: middle;
  margin-left: 5px;
}
@keyframes spinit {
  0% {
    top: 0em;
  }
  50% {
    top: -5em;
  }
  100% {
    top: -9em;
  }
}
@keyframes grow {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeinout {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes ellipsis {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}
html body a {
  text-decoration: none;
  color: var(--blue);
  cursor: pointer;
}
html body a:hover {
  text-decoration: none;
}
html body .btn {
  cursor: pointer;
  border-radius: var(--border-radius-small);
  background: var(--blue);
  color: var(--neutral-1);
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
html body .btn:focus-visible {
  outline: var(--neutral-2) solid 3px;
  box-shadow: none;
}
html body .btn.disabled {
  cursor: not-allowed;
  opacity: 0.3;
  color: var(--neutral-1);
}
html body .btn:hover {
  background-color: var(--blue-hover);
}
html body .btn.btn-xs {
  padding: 0.1rem 0.3rem;
  font-size: 0.6rem;
}
html body .btn.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
html body .btn.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}
html body .btn.btn-block {
  display: block;
  width: 100%;
}
html body .btn.btn-clear {
  background: none !important;
}
html body .btn.btn-clear:hover {
  background-color: var(--neutral-5) !important;
}
html body .btn.bg-green:hover {
  background-color: var(--green-hover) !important;
}
html body .btn.bg-red:hover {
  background-color: var(--red-hover) !important;
}
html body .btn.bg-neutral-4:hover {
  background-color: var(--neutral-3) !important;
}
html body .btn.bg-neutral-5:hover {
  background-color: var(--neutral-4) !important;
}
html body .btn.bg-neutral-6:hover {
  background-color: var(--neutral-5) !important;
}
html body .btn.bg-neutral-7:hover {
  background-color: var(--neutral-6) !important;
}
html body .btn.bg-neutral-8:hover {
  background-color: var(--neutral-7) !important;
}
html body .btn.bg-neutral-9:hover {
  background-color: var(--neutral-8) !important;
}
html body .btn.bg-accent:hover {
  background-color: var(--accent-light) !important;
}
html body .btn.btn-square {
  border-radius: 0;
}
html body a.logout {
  color: var(--neutral-2) !important;
  font-size: 20px;
  cursor: pointer;
}
html body a.logout:hover {
  color: var(--blue) !important;
}
html body hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--neutral-4);
}
html body .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
html body .login-container {
  background-position: center;
  padding: 50px 30px 0 30px;
}
html body #login {
  background-color: var(--neutral-7);
  max-width: 360px;
  margin: auto;
  padding: 120px 30px 30px;
  position: relative;
  box-shadow: 5px 5px 10px #000;
  border-radius: var(--border-radius);
  overflow: visible;
  width: 100%;
}
html body #login h1,
html body #login h2 {
  text-transform: none !important;
  text-align: center;
  margin-bottom: 20px;
}
html body #login img.logo {
  width: calc(100% - 50px);
  margin: 0 25px;
  position: absolute;
  top: -35px;
  left: 0;
}
html body #login .q-field {
  margin-bottom: 1px;
}
html body #login .q-form {
  overflow: auto;
}
html body .google {
  background-color: var(--google-blue);
  font-family: "Roboto", sans-serif;
  height: 40px;
  padding: 0 8px 0 0;
  line-height: 40px;
  text-align: left;
  font-weight: bold;
  width: 100%;
  border-radius: var(--border-radius-small);
  color: var(--neutral-1);
  align-items: center;
  cursor: pointer;
  border: solid 1px var(--google-blue);
}
html body .google::before {
  content: "";
  background-image: url("../assets/_img/styles/google.png");
  background-color: var(--white);
  background-repeat: no-repeat;
  background-position: center;
  height: 38px;
  width: 38px;
  margin-right: 9px;
  display: block;
  float: left;
  background-size: 22px;
  border-top-left-radius: var(--border-radius-small);
  border-bottom-left-radius: var(--border-radius-small);
}
html body .accordion .q-item {
  background-color: var(--neutral-9);
  margin-bottom: 1px;
}
html body .accordion .q-item__section--avatar {
  min-width: max-content;
}
html body .accordion .accordion-body {
  background-color: var(--neutral-8);
  padding: 20px;
}
html body .accordion .accordion-body .hk-table .hk-table-column {
  background-color: var(--neutral-9);
}
html body .hk-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  background-color: var(--neutral-6);
  margin-bottom: 20px;
  border-radius: var(--border-radius);
  overflow: auto;
}
html body .hk-card::-webkit-scrollbar {
  display: none !important;
}
html body .hk-card .card-header {
  padding: 15px;
  margin-bottom: 0;
  background-color: var(--neutral-8);
  border-bottom: 1px solid var(--neutral-6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 62px;
}
html body .hk-card .card-header .btn {
  background-color: var(--neutral-5);
  transition: background-color 0.2s linear;
}
html body .hk-card .card-header .btn:hover {
  background-color: var(--neutral-4);
}
html body .hk-card .card-header h1,
html body .hk-card .card-header h2,
html body .hk-card .card-header h3 {
  font-size: var(--font-size);
  font-family: "Open Sans", sans-serif;
  line-height: normal;
  margin: 0;
}
html body .hk-card .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
html body .hk-card .card-body:empty {
  display: none;
}
html body .hk-card .card-footer {
  padding: 12px 15px;
  background-color: var(--neutral-8);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
html body .hk-card .card-image {
  position: relative;
  min-height: 180px;
  width: 100%;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 -5px 25px black;
  padding: 10px;
}
html body .hk-card .card-image h2,
html body .hk-card .card-image .image-title {
  font-size: 20px;
  text-align: center;
  font-family: unset;
  opacity: 0.8;
  text-shadow: 0 0 3px var(--black);
}
html body .hk-card .card-image .link {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  opacity: 0.5;
}
html body .hk-card .card-image .link:hover {
  opacity: 1;
}
html body .hk-card.no-margin {
  margin-bottom: 0;
}
html body .hk-card.banner {
  flex-direction: row;
  min-height: 242px;
}
html body .hk-card.banner .card-body {
  flex: none;
  width: 250px;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
html body .hk-card.banner .card-body h3 {
  margin-bottom: 10px;
}
html body .hk-card.banner .card-body .btn {
  align-self: flex-start;
}
html body .hk-card.banner-small {
  flex-direction: column;
}
html body .hk-card.small {
  font-size: 12px;
}
html body .hk-card.small .card-body {
  padding: 1rem;
}
html body .hk-card.small .card-header {
  font-size: 15px;
  padding: 0.5rem 1rem;
  min-height: 52px;
}
html body .hk-card-deck {
  display: flex;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
}
html body .hk-card-deck .hk-card {
  flex: 1 1 100%;
  margin-right: 15px;
  margin-bottom: 0;
  margin-left: 15px;
}
@media only screen and (max-width: 992px) {
  html body .hk-card-deck .hk-card {
    margin-bottom: 25px;
  }
}
html body .hk-roll.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
html body .hk-rolls {
  top: 50px;
  position: fixed;
  display: flex;
  z-index: 9000;
  pointer-events: none;
  width: 100%;
}
html body .hk-rolls.center-top {
  justify-content: center;
}
html body .hk-rolls .rolls {
  pointer-events: auto;
  width: 300px;
  padding: 10px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}
html body .hk-rolls .rolls .q-btn {
  pointer-events: auto;
}
html body .hk-rolls .rolls::-webkit-scrollbar {
  display: none !important;
}
html body .handle-xp {
  display: flex;
  justify-content: center;
}
html body .handle-xp .q-field {
  height: 77px;
  margin-right: 1px;
  max-width: 150px;
}
html body .handle-xp .q-field__control {
  height: 77px;
}
html body .handle-xp .q-field__control input {
  text-align: center;
  font-size: 20px;
}
html body .hk-table-column {
  background: var(--neutral-8);
}
html body .hk-table-column.hk-table-header {
  background: none;
  padding: 5px 10px !important;
  font-weight: bold;
}
html body .hk-table-column .image {
  width: 46px;
  height: 46px;
  display: block;
  background-size: cover;
  background-position: top center;
  border: solid 1px var(--neutral-2);
  background-color: var(--neutral-9);
  color: var(--neutral-2);
  font-size: 34px;
}
html body .hk-table-column .image i::before {
  vertical-align: 2px;
}
html body .hk-table-column .actions {
  display: flex;
  justify-content: flex-end;
  padding: 9px 12px;
  user-select: none;
}
html body .hk-table-column .q-field {
  width: 60px;
}
html body .hk-table-column .q-field .row {
  padding: 0;
}
html body .hk-table-column .q-field__control {
  height: 26px;
}
html body .hk-table-column .q-field input {
  text-align: center;
  height: 26px;
  line-height: 26px;
  padding: 0 5px;
}
html body .hk-table .hk-collapsed-column {
  background: var(--neutral-9);
}
html body .drawer {
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 40px auto;
  grid-template-areas: "hide" "content";
}
html body .drawer-wrapper {
  position: fixed;
  right: 0;
  top: 50px;
  height: calc(100vh - 50px);
  width: 420px;
  max-width: 95%;
  z-index: 99;
  box-shadow: 0 10px 15px var(--black);
  background: var(--neutral-8);
}
html body .drawer .q-scrollarea {
  height: 100%;
  grid-area: content;
}
html body .drawer a.hide {
  grid-area: hide;
  padding-left: 10px;
  width: 100%;
  font-size: 15px;
  line-height: 40px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1000;
  background: var(--neutral-9);
  color: var(--neutral-1) !important;
}
html body .drawer__content {
  padding: 15px;
  overflow: auto;
}
html body .drawer__content.monster-card {
  background-color: #f7e4ba !important;
  background-image: url("../assets/_img/styles/monster-card-bg.png") !important;
}
html body .drawer__content::-webkit-scrollbar {
  display: none !important;
}
html body .drawer .hk-table .hk-table-column,
html body .drawer .hk-table .hk-collapsed-column {
  background: var(--neutral-9);
}
html body .drawer .hk-table .hk-table-column.hk-table-header,
html body .drawer .hk-table .hk-collapsed-column.hk-table-header {
  background: none;
}
html body .drawer .target-item .ac_wrapper {
  background-color: var(--neutral-5);
}
html body .drawer .q-item {
  background-color: var(--neutral-9) !important;
}
html body .drawer .q-field__control .q-item {
  background: none !important;
}
html body .drawer ul.entities li {
  background-color: var(--neutral-8);
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: background-color 0.2s linear;
}
html body .drawer ul.entities li:hover {
  background-color: var(--neutral-7);
}
html body .drawer table tr td,
html body .drawer .table tr td {
  background-color: var(--neutral-8);
}
html body table.table,
html body table {
  border-collapse: separate !important;
  border-spacing: 0 1px;
  width: 100%;
  margin-bottom: 20px;
}
html body table.table p,
html body table p {
  margin: 0;
}
html body table.table thead,
html body table thead {
  margin-bottom: 1px !important;
}
html body table.table thead th,
html body table thead th {
  text-align: left !important;
  padding: 10px;
  border: none;
}
html body table.table tbody tr,
html body table tbody tr {
  margin-bottom: 1px !important;
}
html body table.table tbody tr th,
html body table tbody tr th {
  text-align: left;
  padding: 10px;
  border: none;
}
html body table.table tbody tr td,
html body table tbody tr td {
  width: max-content;
  padding: 10px;
  border-top: none;
  background-color: var(--neutral-8);
  position: relative;
}
html body table.table tbody tr td .actions,
html body table tbody tr td .actions {
  padding: 5px 0.75rem;
}
html body table.table tbody tr td .actions-dropdown,
html body table tbody tr td .actions-dropdown {
  width: 30px;
  text-align: center;
}
html body table.table tbody tr td .actions-dropdown a,
html body table tbody tr td .actions-dropdown a {
  display: block;
}
html body table.table tbody tr td .fa-ellipsis-v,
html body table tbody tr td .fa-ellipsis-v {
  line-height: 22px;
}
html body table.table tbody tr td span.bind,
html body table tbody tr td span.bind {
  border: solid 1px #2c97de;
  display: inline-block;
  min-width: 20px;
  padding: 0 5px;
  text-align: center;
}
html body table.table tbody tr td.actions router-link,
html body table.table tbody tr td.actions a,
html body table.table tbody tr td.actions .disabled,
html body table tbody tr td.actions router-link,
html body table tbody tr td.actions a,
html body table tbody tr td.actions .disabled {
  font-size: 15px;
  line-height: 0px;
}
html body table.table tbody tr td.actions .disabled,
html body table tbody tr td.actions .disabled {
  opacity: 0.3;
}
html body table.table tbody tr td.index,
html body table tbody tr td.index {
  width: 1% !important;
  white-space: nowrap;
}
html body table.table tbody tr td.img,
html body table tbody tr td.img {
  padding: 3px;
  width: 46px;
  height: 46px;
  background-size: cover;
  background-position: top center;
  background-color: var(--black);
  border: solid 1px var(--neutral-3) !important;
}
html body table.table tbody tr td.img img,
html body table tbody tr td.img img {
  width: 40px;
  height: 40px;
}
html body table.table tbody tr:focus td,
html body table tbody tr:focus td {
  background-color: var(--neutral-9);
}
html body table.table tbody tr:focus .actions,
html body table tbody tr:focus .actions {
  display: flex !important;
}
html body table.table tbody tr.openSlot td,
html body table tbody tr.openSlot td {
  background: none;
  border: dashed 1px var(--neutral-4) !important;
}
html body table.table tbody tr.openSlot td .slot,
html body table tbody tr.openSlot td .slot {
  display: flex;
  justify-content: space-between;
}
html body table.table.table-sm,
html body table.table-sm {
  font-size: 13px;
}
html body table.table.table-sm td,
html body table.table.table-sm th,
html body table.table-sm td,
html body table.table-sm th {
  padding: 5px;
}
html body .hk-popover {
  user-select: none;
  cursor: pointer;
}
html body .hk-popover:hover {
  color: var(--blue);
}
html body .target-item-wrapper {
  border-radius: var(--border-radius-small);
  overflow: hidden;
  width: 100%;
}
html body .target-item {
  display: grid;
  grid-template-columns: 40px 40px 1fr;
  grid-template-rows: auto;
  grid-gap: 1px;
  line-height: 40px;
  user-select: none;
  border-radius: var(--border-radius-small);
  width: 100%;
}
html body .target-item.hasInitiative {
  grid-template-columns: 40px 40px 40px 1fr;
}
html body .target-item .initiative,
html body .target-item .ac,
html body .target-item .target-avatar {
  text-align: center;
  height: 40px;
  width: 40px;
}
html body .target-item .initiative {
  padding: 0 5px;
  font-weight: bold;
}
html body .target-item .ac_wrapper {
  position: relative;
  cursor: pointer;
  color: var(--neutral-4);
}
html body .target-item .ac_wrapper i,
html body .target-item .ac_wrapper .ac {
  position: absolute;
  line-height: 40px;
  width: 100%;
  text-align: center;
}
html body .target-item .ac_wrapper i {
  font-size: 33px;
  margin-top: 1px;
}
html body .target-item .ac_wrapper .ac {
  font-weight: bold;
  filter: invert(1) grayscale(1) brightness(1.3) contrast(9000);
  mix-blend-mode: luminosity;
  opacity: 0.95;
}
html body .target-item .target-avatar {
  width: 40px;
  height: 40px;
  background-color: var(--neutral-9);
  font-size: 20px;
  line-height: 40px;
  overflow: hidden;
}
html body .target-item .target-avatar [class^=hki-],
html body .target-item .target-avatar [class*=" hki-"] {
  font-size: 28px;
  margin-left: -1px;
}
html body .target-item .target-avatar span {
  position: relative;
}
html body .target-item .q-linear-progress .health-bar {
  padding: 0 10px;
  font-size: 13px;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 5px;
  line-height: 40px;
  color: var(--neutral-1);
}
html body .target-item .q-linear-progress .health-bar .conditions {
  position: absolute;
  right: 0;
  top: 0;
}
html body .target-item .q-linear-progress .health-bar .conditions .condition {
  display: block;
  width: 12px;
  height: 12px;
  border: solid 1px var(--neutral-1);
  margin-left: 1px;
  cursor: pointer;
}
html body .target-item .q-linear-progress .health-bar .hp .health,
html body .target-item .q-linear-progress .health-bar .hp .temp {
  text-align: right;
  cursor: pointer;
  border-radius: var(--border-radius-small);
  font-size: 15px;
}
html body .target-item .q-linear-progress .health-bar .hp .health .current,
html body .target-item .q-linear-progress .health-bar .hp .temp .current {
  font-weight: bold;
}
html body .target-item .q-linear-progress .health-bar .hp .health .max,
html body .target-item .q-linear-progress .health-bar .hp .temp .max {
  opacity: 0.8;
}
html body .target-item .q-linear-progress .health-bar .hp .save {
  margin-right: 4px;
}
html body .currency {
  margin: auto;
  display: flex;
  justify-content: center;
  max-width: 400px;
  text-align: center;
}
html body .currency img {
  height: 25px;
  margin-bottom: 10px;
}
html body .currency div {
  margin-right: 5px;
}
html body .currency div:last-child {
  margin-right: 0;
}
html body .currency .q-field .row {
  text-align: center;
  padding: 0 5px;
}
html body .currency .q-field input {
  text-align: center;
}
html body .currency .q-field__label {
  font-size: 10px;
}
html body ul.settings {
  border-top: solid 1px var(--neutral-5);
  padding: 0 !important;
  list-style: none;
  margin-bottom: 60px;
}
html body ul.settings li {
  font-size: 13px;
  padding: 15px 5px;
  border-bottom: solid 1px var(--neutral-5);
}
html body ul.settings li .btn {
  color: var(--neutral-2) !important;
}
html body ul.entities {
  list-style: none;
  padding: 0;
}
html body ul.entities li {
  display: grid;
  background-color: var(--neutral-7);
  grid-template-columns: auto max-content;
  margin-bottom: 1px;
  vertical-align: center;
  line-height: 46px;
  padding: 0 10px;
}
html body ul.entities li .actions {
  display: flex;
  justify-content: flex-end;
  background: none !important;
  padding-right: 10px;
}
html body ul.entities li .actions a {
  color: var(--neutral-2) !important;
  margin-left: 10px;
}
html body ul.entities li .actions a:hover {
  text-decoration: none;
}
html body ul.entities li:hover {
  background-color: var(--neutral-9);
}
html body ul.entities li:hover .hover-hide {
  display: none;
}
html body ul.entities li .fa-ellipsis-v {
  line-height: 22px !important;
}
html body ul.entities li .img {
  width: 46px;
  height: 46px;
  display: block;
  background-size: cover;
  background-position: top center;
  border: solid 1px var(--neutral-3);
  background-color: var(--neutral-10);
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  color: var(--neutral-2);
}
html body ul.entities.hasImg li {
  padding-left: 0;
  grid-template-columns: 55px auto max-content;
}
html body ul.entities.hasImg li .overview-item {
  display: grid;
  grid-template-columns: auto 20px;
  grid-gap: 5px;
}
html body .manual-input {
  height: 90px;
  font-size: 50px;
}
html body .manual-input .q-field {
  height: 90px;
}
html body .manual-input .q-field__control {
  height: 90px;
}
html body .manual-input .q-field__control input {
  text-align: center;
}
html body .player-initiative .q-field__control {
  padding: 0;
}
html body .player-initiative .q-field__control input {
  padding-left: 12px;
}
html body .q-table {
  border-spacing: 0 1px;
}
html body .q-table .q-field {
  width: 60px;
}
html body .q-table .q-field .row {
  padding: 0;
}
html body .q-table .q-field__control {
  height: 26px;
}
html body .q-table .q-field input {
  text-align: center;
  height: 26px;
  line-height: 26px;
  padding: 0 5px;
}
html body .q-table .q-td {
  background-color: var(--neutral-7);
}
html body .q-table .q-td.avatar {
  padding: 0;
  width: 55px;
  height: 55px;
  border: solid 1px var(--neutral-4);
  background-size: cover;
  background-position: center top;
  background-color: var(--neutral-8);
  color: var(--neutral-2);
  text-align: center;
  font-size: 45px;
  line-height: 55px;
  box-sizing: border-box;
}
html body .q-table .monster {
  column-count: 2;
}
html body .q-table .monster .skills {
  column-count: 2;
}
html body .q-table .monster-card {
  padding: 10px;
}
html body .monster-card {
  background-color: #f7e4ba !important;
  background-image: url("../assets/_img/styles/monster-card-bg.png") !important;
}
@media only screen and (max-width: 1530px) {
  html body .monster-table .hk-table .hk-collapsed-column .monster {
    column-count: 1;
  }
  html body .q-table .monster {
    column-count: 1;
  }
}
html body .slideInRight {
  animation-duration: 0.5s;
}
html body .slideOutRight {
  animation-duration: 0.5s;
}
@media only screen and (max-width: 575px) {
  html body table tr td .actions {
    display: none !important;
    background-color: var(--neutral-9);
    right: 20px;
  }
  html body table tr td .actions a {
    background: var(--neutral-3);
  }
}
html body .snotifyToast {
  border-radius: var(--border-radius) !important;
}
html body .snotify-leftTop,
html body .snotify-centerTop,
html body .snotify-rightTop {
  top: 60px !important;
}
html body .snotifyToast__title {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 15px !important;
}
html body .snotifyToast__body {
  font-size: 12px !important;
}
html body .snotifyToast__buttons {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
html body .snotifyToast__buttons button {
  cursor: pointer;
}
html body .snotify-simple {
  border: solid 1px var(--neutral-5);
  user-select: none;
}
html body .snotify-simple .snotifyToast__inner {
  padding: 13px 15px 15px 15px !important;
  background-color: var(--neutral-8) !important;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__title {
  color: var(--neutral-2) !important;
  width: 100%;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__title .target {
  display: grid;
  grid-template-columns: 30px 30px 1fr;
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 10px;
  text-transform: none !important;
  background-color: var(--neutral-10);
  position: relative;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__title .target .ac {
  text-align: center;
  background-color: var(--neutral-10);
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__title .target .image {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: center top;
  background-color: var(--neutral-10);
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__title .target .name {
  padding: 0 10px;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__title .target:hover .name {
  direction: rtl;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body {
  color: var(--neutral-2) !important;
  width: 100%;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body h2 {
  text-transform: none !important;
  margin-bottom: 5px !important;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body small {
  display: inline-block;
  line-height: 12px !important;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body .title {
  margin-bottom: 15px !important;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body .display-rolls {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body .display-rolls div.roll {
  border: solid 1px var(--neutral-3);
  text-align: center;
  padding: 5px 0;
  flex-direction: column;
  flex: 1 1 50%;
  margin: 5px;
  position: relative;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body .display-rolls div.roll h2 {
  font-size: 40px;
  margin-bottom: 0 !important;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body .display-rolls div.roll .top,
html body .snotify-simple .snotifyToast__inner .snotifyToast__body .display-rolls div.roll .bottom {
  font-size: 15px;
  height: 20px;
  line-height: 20px;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body .display-rolls div.roll .advantage {
  position: absolute;
  background: var(--neutral-10);
  padding: 0 10px;
  top: -5px;
  left: 50%;
  transform: translate(-50%, -50%);
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body.roll {
  width: calc(100% + 30px);
  margin: -13px -15px -15px -15px;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body.roll .roll_title {
  background: var(--neutral-8);
  font-size: 15px;
  padding: 10px;
  text-align: center;
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body.roll .rolled {
  line-height: 130px;
  width: 100%;
  text-align: center;
  font-size: 80px;
  font-weight: bold;
  background-color: var(--neutral-7);
}
html body .snotify-simple .snotifyToast__inner .snotifyToast__body.roll .roll_footer {
  text-align: center;
  padding: 10px;
  font-size: 15px;
  background-color: var(--neutral-8);
}
html body .snotify-simple .snotifyToast__buttons {
  background: var(--neutral-9) !important;
}
html body .snotify-error .snotifyToast__inner {
  background-color: var(--red) !important;
}
html body .snotify-error .snotifyToast__buttons {
  background-color: var(--red) !important;
}
html body .snotify-warning .snotifyToast__inner {
  background-color: var(--orange) !important;
}
html body .snotify-warning .snotifyToast__buttons {
  background-color: var(--orange) !important;
}
html body .snotify-icon.snotify-icon--error {
  background-image: url("../assets/_img/styles/sword-break.png");
  background-size: cover;
}
html body .snotify-success .snotifyToast__inner {
  background-color: var(--green) !important;
}
html body .snotify-success .snotifyToast__buttons {
  background-color: var(--green) !important;
}
html body .snotify-icon.snotify-icon--success {
  background-image: url("../assets/_img/styles/d20-white.png");
  background-size: cover;
}
html body input[type=number]::-webkit-outer-spin-button,
html body input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
html body input[type=number] {
  -moz-appearance: textfield;
}
html body .croppa-container canvas {
  border: solid 1px var(--neutral-2);
  background-color: var(--neutral-8) !important;
}
html body .q-pagination {
  justify-content: center;
}
html body .q-pagination .q-btn {
  background-color: var(--neutral-8);
  margin-left: 1px;
  color: var(--neutral-2) !important;
}
html body .q-btn {
  border-radius: var(--border-radius);
  font-weight: bold;
}
html body .q-btn:focus-visible {
  outline: var(--neutral-2) solid 3px !important;
}
html body .q-btn-group .q-btn {
  border: solid 1px transparent;
}
html body .q-btn-group .q-btn:focus-visible {
  outline: none !important;
  border-color: var(--neutral-2);
}
html body .q-menu .q-list .q-item__section--avatar {
  min-width: 35px;
}
html body .q-menu table td {
  background-color: var(--neutral-9) !important;
}
html body .q-list .q-expansion-item .q-item__section.small-toggle {
  padding-right: 10px;
}
html body .q-list .q-expansion-item .q-item__section.hidden-toggle {
  display: none !important;
}
html body .q-dialog__inner .hk-card .card-header {
  position: sticky;
  top: 0;
  z-index: 1;
}
html body .sticky-header-table {
  max-height: 300px;
}
html body .sticky-header-table .q-table__top,
html body .sticky-header-table .q-table__bottom,
html body .sticky-header-table thead tr:first-child th {
  background-color: var(--neutral-6);
}
html body .sticky-header-table thead tr th {
  position: sticky;
  z-index: 1;
}
html body .sticky-header-table thead tr:last-child th {
  top: 48px;
}
html body .sticky-header-table thead tr:first-child th {
  top: 0;
}
html body .q-tabs--dense .q-tab {
  min-height: 35px;
}
html body .q-tabs--dense .q-tab__icon {
  font-size: 15px;
}
html body .q-tabs--dense .q-tabs__arrow {
  line-height: 35px;
}
html body .q-tabs__arrow {
  line-height: 48px;
  font-size: 30px;
  text-shadow: 0px 0px 3px #000;
  color: var(--neutral-1);
}
html body .q-notification {
  border-radius: var(--border-radius);
}
html body .q-notifications__list--top {
  top: 120px;
}
@media only screen and (max-width: 576px) {
  html body .d-xs-flex {
    display: flex !important;
  }
  html body .d-xs-none {
    display: none !important;
  }
  html body .d-xs-block {
    display: block !important;
  }
  html body .d-xs-table-cell {
    display: table-cell !important;
  }
  html body .d-xs-inline {
    display: inline !important;
  }
  html body .d-xs-inline-block {
    display: inline-block !important;
  }
  html body .hk-layout {
    padding-top: 38px;
  }
  html body .hk-layout .breadcrumb {
    margin-bottom: 0;
    background: var(--neutral-6);
    border-radius: 0;
    border: none;
    padding: 10px 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    font-size: 13px;
    line-height: normal;
  }
  html body .hk-layout .breadcrumb .q-breadcrumbs > div {
    flex-wrap: nowrap;
    min-width: 0;
    white-space: nowrap;
  }
  html body .hk-layout .breadcrumb .q-breadcrumbs > div :not(:first-child):not(:last-child).flex {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  html body .hasSide {
    grid-template-columns: auto;
  }
  html body .hk-card.banner {
    flex-direction: column;
  }
  html body .hk-card.banner .card-body {
    width: unset;
    max-width: unset;
  }
}
@media only screen and (min-width: 576px) {
  html body .container {
    max-width: 540px;
  }
  html body .d-sm-flex {
    display: flex !important;
  }
  html body .d-sm-none {
    display: none !important;
  }
  html body .d-sm-block {
    display: block !important;
  }
  html body .d-sm-table-cell {
    display: table-cell !important;
  }
  html body .d-sm-inline {
    display: inline !important;
  }
  html body .d-sm-inline-block {
    display: inline-block !important;
  }
}
@media only screen and (min-width: 768px) {
  html body .container {
    max-width: 720px;
  }
  html body .d-md-flex {
    display: flex !important;
  }
  html body .d-md-none {
    display: none !important;
  }
  html body .d-md-block {
    display: block !important;
  }
  html body .d-md-table-cell {
    display: table-cell !important;
  }
  html body .d-md-inline {
    display: inline !important;
  }
  html body .d-md-inline-block {
    display: inline-block !important;
  }
}
@media only screen and (min-width: 992px) {
  html body .container {
    max-width: 960px;
  }
  html body .d-lg-flex {
    display: flex !important;
  }
  html body .d-lg-none {
    display: none !important;
  }
  html body .d-lg-block {
    display: block !important;
  }
  html body .d-lg-table-cell {
    display: table-cell !important;
  }
  html body .d-lg-inline {
    display: inline !important;
  }
  html body .d-lg-inline-block {
    display: inline-block !important;
  }
  html body .hk-card-deck .hk-card {
    flex: 1 0 0%;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1200px) {
  html body .container {
    max-width: 1280px;
  }
  html body .drawer {
    width: 420px;
  }
  html body .d-xl-flex {
    display: flex !important;
  }
  html body .d-xl-none {
    display: none !important;
  }
  html body .d-xl-block {
    display: block !important;
  }
  html body .d-xl-inline {
    display: inline !important;
  }
  html body .d-xl-inline-block {
    display: inline-block !important;
  }
}