
.rolls {
	padding: 10px;
	box-shadow: none !important;
	scrollbar-width: none;
	height: 100vh;

	&::-webkit-scrollbar {
		display: none;
	}

	&:focus {
		outline: none;
	}
}

.animated {
	animation-duration: 0.4s !important;
}
