
.hk-compendium-image {
	width: 100%;
	height: 100%;
	overflow: hidden;

	&__thumbnail {
		aspect-ratio: 1 / 1;

		img {
			aspect-ratio: 1 / 1;
		}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}
