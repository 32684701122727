[data-theme="light"] {
	body {
		background: $neutral-10;

		.btn {
			color: $neutral-11;

			&-clear {
				color: $neutral-2;

				&:hover {
					color: $neutral-11;
				}
			}
			&.bg-neutral-5,
			&.bg-neutral-6,
			&.bg-neutral-8,
			&.bg-neutral-9 {
				color: $neutral-2;
			}
		}

		.live {
			color: $neutral-2;

			&::before {
				background-color: $neutral-3;
			}

			&.active {
				&::before {
					background-color: $neutral-3;
				}
			}
		}

		ul.entities {
			.img {
				background-color: $neutral-2;
				color: $neutral-8;
			}
		}

		.hk-table {
			&-column {
				.image {
					background-color: $neutral-2;
					color: $neutral-8;
				}
			}
		}

		.target-item {
			.img {
				color: $neutral-8;
				background-color: $neutral-2;
			}
			.ac_wrapper {
				i {
					color: $neutral-2;
				}
				.ac {
					color: $neutral-11;
				}
			}
		}

		.google {
			background-color: $white;
			color: $neutral-4;
			border-color: transparent;
			box-shadow: 1px 1px 1px $neutral-8;
		}
	}
}
