/*
TO UPDATE THE FONT

1. Go to: fontello.com
2. Import ../assets/hk-icon-font/harmless-key.svg
3. Add svg's of the icons you wish to add
4. Download the updated font
5. Replace the files in ../assets/hk-icon-font with the files from /font folder in you download
6. Add the classes to the CSS below (don't replace the existing CSS below, it's modified for HK)

*/

@font-face {
	font-family: 'harmless-key, sans-serif';
	src: url('../assets/hk-icon-font/harmless-key.eot?29559614');
	src: url('../assets/hk-icon-font/harmless-key.eot?29559614#iefix') format('embedded-opentype'),
		url('../assets/hk-icon-font/harmless-key.woff2?29559614') format('woff2'),
		url('../assets/hk-icon-font/harmless-key.woff?29559614') format('woff'),
		url('../assets/hk-icon-font/harmless-key.ttf?29559614') format('truetype'),
		url('../assets/hk-icon-font/harmless-key.svg?29559614#harmless-key') format('svg');
	font-weight: normal;
	font-style: normal;
}
[class^='hki-']:before,
[class*=' hki-']:before {
	font-family: 'harmless-key, sans-serif';
	font-style: normal;
	font-weight: normal;
	speak: never;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* you can be more comfortable with increased icons size */
	font-size: 120%;

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.hki {
	&-dragon:before,
	&-monster:before {
		content: '\e800';
	} /* '' */
	&-spiked-wall:before,
	&-environment:before {
		content: '\e801';
	} /* '' */
	&-axe:before {
		content: '\e802';
	} /* '' */
	&-hooded:before,
	&-player:before {
		content: '\e803';
	} /* '' */
	&-wolf:before,
	&-companion:before {
		content: '\e804';
	} /* '' */
	&-sword-break:before {
		content: '\e805';
	} /* '' */
	&-swords-clash:before {
		content: '\e806';
	} /* '' */
	&-heal:before {
		content: '\e807';
	} /* '' */
	&-incapacitated:before,
	&-stunned:before {
		content: '\e808';
	} /* '' */
	&-invisible:before {
		content: '\e809';
	} /* '' */
	&-surprised:before {
		content: '\e80a';
	} /* '' */
	&-paralyzed:before {
		content: '\e80b';
	} /* '' */
	&-petrified:before {
		content: '\e80c';
	} /* '' */
	&-poisoned:before {
		content: '\e80d';
	} /* '' */
	&-restrained:before {
		content: '\e80e';
	} /* '' */
	&-unconscious:before {
		content: '\e80f';
	} /* '' */
	&-blinded:before {
		content: '\e810';
	} /* '' */
	&-charmed:before {
		content: '\e811';
	} /* '' */
	&-deafened:before {
		content: '\e812';
	} /* '' */
	&-exhaustion:before,
	&-prone:before {
		content: '\e813';
	} /* '' */
	&-frightened:before {
		content: '\e814';
	} /* '' */
	&-grappled:before {
		content: '\e815';
	} /* '' */
}
