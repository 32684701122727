
.q-scrollarea {
	height: 100%;
	position: relative;

	&::v-deep {
		.q-scrollarea__content {
			width: 100%;
		}
	}
}
