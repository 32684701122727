.splitpanes {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
}

.splitpanes--vertical {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}

.splitpanes--horizontal {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.splitpanes--dragging * {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.splitpanes__pane {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.splitpanes--vertical .splitpanes__pane {
	-webkit-transition: width 0.2s ease-out;
	-o-transition: width 0.2s ease-out;
	transition: width 0.2s ease-out;
}

.splitpanes--horizontal .splitpanes__pane {
	-webkit-transition: height 0.2s ease-out;
	-o-transition: height 0.2s ease-out;
	transition: height 0.2s ease-out;
}

.splitpanes--dragging .splitpanes__pane {
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}

.splitpanes__splitter {
	-ms-touch-action: none;
	touch-action: none;
}

.splitpanes--vertical > .splitpanes__splitter {
	min-width: 1px;
	cursor: col-resize;
}

.splitpanes--horizontal > .splitpanes__splitter {
	min-height: 1px;
	cursor: row-resize;
}

.splitpanes.default-theme .splitpanes__pane {
	background-color: $neutral-6-transparent;

	&:has(.splitpanes) {
		background: none;
	}
}

.splitpanes.default-theme .splitpanes__splitter {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.splitpanes.default-theme .splitpanes__splitter:before,
.splitpanes.default-theme .splitpanes__splitter:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	background-color: #ffffff81;
	-webkit-transition: background-color 0.3s;
	-o-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.splitpanes.default-theme .splitpanes__splitter:hover:before,
.splitpanes.default-theme .splitpanes__splitter:hover:after {
	background-color: #ffffff81;
}

.splitpanes.default-theme .splitpanes__splitter:first-child {
	cursor: auto;
}

.default-theme.splitpanes .splitpanes .splitpanes__splitter {
	z-index: 1;
}

.default-theme.splitpanes--vertical > .splitpanes__splitter,
.default-theme .splitpanes--vertical > .splitpanes__splitter {
	width: 7px;
	margin-left: -1px;
}

.default-theme.splitpanes--vertical > .splitpanes__splitter:before,
.default-theme.splitpanes--vertical > .splitpanes__splitter:after,
.default-theme .splitpanes--vertical > .splitpanes__splitter:before,
.default-theme .splitpanes--vertical > .splitpanes__splitter:after {
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 1px;
	height: 30px;
}

.default-theme.splitpanes--vertical > .splitpanes__splitter:before,
.default-theme .splitpanes--vertical > .splitpanes__splitter:before {
	margin-left: -2px;
}

.default-theme.splitpanes--vertical > .splitpanes__splitter:after,
.default-theme .splitpanes--vertical > .splitpanes__splitter:after {
	margin-left: 1px;
}

.default-theme.splitpanes--horizontal > .splitpanes__splitter,
.default-theme .splitpanes--horizontal > .splitpanes__splitter {
	height: 7px;
	margin-top: -1px;
}

.default-theme.splitpanes--horizontal > .splitpanes__splitter:before,
.default-theme.splitpanes--horizontal > .splitpanes__splitter:after,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:before,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:after {
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translate(-50%);
	width: 30px;
	height: 1px;
}

.default-theme.splitpanes--horizontal > .splitpanes__splitter:before,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:before {
	margin-top: -2px;
}

.default-theme.splitpanes--horizontal > .splitpanes__splitter:after,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:after {
	margin-top: 1px;
}
