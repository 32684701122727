@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import "~vue-snotify/styles/material.css";
@import url("https://fonts.googleapis.com/css?family=Fredericka+the+Great&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "hk-icons.scss";
@import "./splitpanes.scss";

/* 2. Themes */
/*********************************************/
@import "./light.scss";

/*
	Table of content
	1. Variables
	2. Defaults
	3. Headings
	4. Classes
	5. Links
	6. Bootstrap overrides
	7. Snotify overrides
	8. Custom scrollbars
	9. Number field
	10. Croppa
*/

/* 2. Defaults */
/*********************************************/
html body {
	background: $neutral-11;
	color: $neutral-1;
	font-size: $font-size;
	font-family: "Open Sans", sans-serif;
	position: relative;

	button {
		all: unset;
		text-align: center;
		box-sizing: border-box;
	}

	*:focus-visible {
		outline-offset: 3px;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	.container-fluid {
		padding: 0;
	}

	.hasSide {
		display: grid;
		grid-template-columns: max-content auto;
	}

	#header {
		display: block;
		height: $header-height;
		line-height: 50px;
		width: 100%;
		background: $neutral-6;
		border-bottom: solid 1px transparent;
		position: fixed;
		top: 0;
		z-index: 999;
		padding: 0 10px 0 5px;
	}
	pre {
		color: $neutral-2;
	}

	.text-uppercase {
		text-transform: uppercase;
	}
	.text-capitalize {
		text-transform: capitalize;
	}
	.text-lowercase {
		text-transform: lowercase;
	}

	.text-shadow {
		text-shadow: 0 0 5px $black;
		&-3 {
			text-shadow: 0 0 3px $black;
		}
		&-4 {
			text-shadow: 0 0 4px $black;
		}
		&-5 {
			text-shadow: 0 0 5px $black;
		}
		&-6 {
			text-shadow: 0 0 6px $black;
		}
		&-7 {
			text-shadow: 0 0 7px $black;
		}
		&-8 {
			text-shadow: 0 0 8px $black;
		}
	}

	/* 3. Heading */
	/*********************************************/
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: bold;
		margin: 0;
	}
	h1 {
		font-size: 25px;
		line-height: 40px;
		margin-bottom: 15px;
		color: $neutral-1;
	}
	h2 {
		font-size: 20px;
		line-height: 35px;
		margin-bottom: 20px;
	}
	h3 {
		font-size: 18px;
		margin-bottom: 15px;
		line-height: 33px;
	}
	h4 {
		font-size: 15px;
		margin-bottom: 15px;
		line-height: 30px;
	}

	/* 4. Classes */
	/*********************************************/
	.white {
		color: $white !important;
	}
	.text {
		&-left {
			text-align: left !important;
		}
	}

	.neutral-1,
	.text-neutral-1 {
		color: $neutral-1 !important;
		path {
			fill: $neutral-1 !important;
		}
	}
	.neutral-2,
	.text-neutral-2 {
		color: $neutral-2 !important;
		path {
			fill: $neutral-2 !important;
		}
	}
	.neutral-3,
	.text-neutral-3 {
		color: $neutral-3 !important;
		path {
			fill: $neutral-3 !important;
		}
	}
	.neutral-4,
	.text-neutral-4 {
		color: $neutral-4 !important;
		path {
			fill: $neutral-4 !important;
		}
	}
	.neutral-5,
	.text-neutral-5 {
		color: $neutral-5 !important;
		path {
			fill: $neutral-5 !important;
		}
	}
	.neutral-6,
	.text-neutral-6 {
		color: $neutral-6 !important;
		path {
			fill: $neutral-6 !important;
		}
	}
	.neutral-7,
	.text-neutral-7 {
		color: $neutral-7 !important;
		path {
			fill: $neutral-7 !important;
		}
	}
	.neutral-8,
	.text-neutral-8 {
		color: $neutral-8 !important;
		path {
			fill: $neutral-8 !important;
		}
	}
	.neutral-9,
	.text-neutral-9 {
		color: $neutral-9 !important;
		path {
			fill: $neutral-9 !important;
		}
	}
	.neutral-10,
	.text-neutral-10 {
		color: $neutral-10 !important;
		path {
			fill: $neutral-10 !important;
		}
	}
	.neutral-11,
	.text-neutral-11 {
		color: $neutral-11 !important;
		path {
			fill: $neutral-11 !important;
		}
	}

	.green {
		color: $green !important;
		path {
			fill: $green !important;
		}
	}
	.blue {
		color: $blue !important;
		path {
			fill: $blue !important;
		}
	}
	.red {
		color: $red !important;
		path {
			fill: $red !important;
		}
	}
	.black {
		color: $black !important;
		path {
			fill: $black !important;
		}
	}
	.patreon-red {
		color: $patreon-red !important;
		path {
			fill: $patreon-red !important;
		}
	}
	.discord-purple {
		color: $discord-purple !important;
		path {
			fill: $discord-purple !important;
		}
	}
	.orange {
		color: $orange !important;
		path {
			fill: $orange !important;
		}
	}
	.yellow {
		color: $yellow !important;
		path {
			fill: $yellow !important;
		}
	}
	.purple {
		path {
			fill: $purple !important;
		}
	}
	.blue-light {
		color: $blue-light !important;
		path {
			fill: $blue-light !important;
		}
	}
	.facebook-blue {
		color: $facebook-blue !important;
		path {
			fill: $facebook-blue !important;
		}
	}
	.green-light {
		color: $green-light !important;
		path {
			fill: $green-light !important;
		}
	}
	.orange-light {
		color: $orange-light !important;
		path {
			fill: $orange-light !important;
		}
	}
	.red-light {
		color: $red-light !important;
		path {
			fill: $red-light !important;
		}
	}
	.yellow-light {
		color: $yellow-light !important;
		path {
			fill: $yellow-light !important;
		}
	}
	.yellow-dark,
	.text-yellow-dark {
		color: $yellow-dark !important;
		path {
			fill: $yellow-dark !important;
		}
	}

	.copper {
		color: $copper !important;
		fill: $copper !important;
	}
	.silver {
		color: $silver !important;
		fill: $silver !important;
	}
	.electrum {
		color: $electrum !important;
		fill: $electrum !important;
	}
	.gold {
		color: $gold !important;
		fill: $gold !important;
	}
	.platinum {
		color: $platinum !important;
		fill: $platinum !important;
	}

	.bg-neutral-1 {
		background-color: $neutral-1 !important;
	}
	.bg-neutral-2 {
		background-color: $neutral-2 !important;
	}
	.bg-neutral-3 {
		background-color: $neutral-3 !important;
	}
	.bg-neutral-4 {
		background-color: $neutral-4 !important;
	}
	.bg-neutral-5 {
		background-color: $neutral-5 !important;
	}
	.bg-neutral-6 {
		background-color: $neutral-6 !important;

		&-transparent {
			background-color: $neutral-6-transparent !important;
		}
	}
	.bg-neutral-7 {
		background-color: $neutral-7 !important;
	}
	.bg-neutral-8 {
		background-color: $neutral-8 !important;
	}
	.bg-neutral-9 {
		background-color: $neutral-9 !important;
	}
	.bg-neutral-10 {
		background-color: $neutral-10 !important;
	}
	.bg-neutral-11 {
		background-color: $neutral-11 !important;
	}

	.bg-white {
		background-color: $white !important;
	}
	.bg-black {
		background-color: $black !important;
	}
	.bg-none {
		background: none !important;
	}

	.bg-green {
		background-color: $green !important;
	}
	.bg-blue {
		background-color: $blue !important;
	}
	.bg-red {
		background-color: $red !important;
	}
	.bg-patreon-red {
		background-color: $patreon-red !important;
	}
	.bg-orange {
		background-color: $orange !important;
	}
	.bg-yellow {
		background-color: $yellow !important;
	}
	.bg-purple {
		background-color: $purple !important;
	}
	.bg-discord-purple {
		background-color: $discord-purple !important;
	}
	.bg-blue-light {
		background-color: $blue-light !important;
	}
	.bg-green-light {
		background-color: $green-light !important;
	}
	.bg-orange-light {
		background-color: $orange-light !important;
	}
	.bg-red-light {
		background-color: $red-light !important;
	}
	.bg-yellow-light {
		background-color: $yellow-light !important;
	}
	.bg-accent {
		background-color: $accent !important;
	}
	.bg-accent-light {
		background-color: $accent-light !important;
	}

	.healing {
		color: $green;
	}
	.acid {
		color: $acid;
	}
	.bludgeoning {
		color: $bludgeoning;
	}
	.cold {
		color: $cold;
	}
	.fire {
		color: $fire;
	}
	.force {
		color: $force;
	}
	.lightning {
		color: $lightning;
	}
	.necrotic {
		color: $necrotic;
	}
	.piercing {
		color: $piercing;
	}
	.poison {
		color: $poison;
	}
	.psychic {
		color: $psychic;
	}
	.radiant {
		color: $radiant;
	}
	.slashing {
		color: $slashing;
	}
	.thunder {
		color: $thunder;
	}
	.non_magical_bludgeoning {
		color: $bludgeoning;
	}
	.non_magical_piercing {
		color: $piercing;
	}
	.non_magical_slashing {
		color: $slashing;
	}

	.border-radius {
		border-radius: $border-radius;

		&-small {
			border-radius: $border-radius-small;
		}
	}

	.written {
		font-family: "Fredericka the Great", cursive;
	}

	.overflow {
		&-hidden {
			overflow: hidden;
		}
		&-auto {
			overflow: auto;
		}
		&-scroll {
			overflow: scroll;
		}
		&-x {
			&-hidden {
				overflow-x: hidden;
			}
			&-auto {
				overflow-x: auto;
			}
			&-scroll {
				overflow-x: scroll;
			}
		}
		&-y {
			&-hidden {
				overflow-y: hidden;
			}
			&-auto {
				overflow-y: auto;
			}
			&-scroll {
				overflow-y: scroll;
			}
		}
	}

	.absolute {
		position: absolute;
	}
	.static {
		position: static;
	}
	.fixed {
		position: fixed;
	}
	.sticky {
		position: sticky;
	}

	//MARGINS
	.m {
		&-0 {
			margin: 0 !important;
		}
		&-1 {
			margin: 0.25rem !important;
		}
		&-2 {
			margin: 0.5rem !important;
		}
		&-3 {
			margin: 1rem !important;
		}
		&-4 {
			margin: 1.5rem !important;
		}
		&-5 {
			margin: 3rem !important;
		}
		&b,
		&y {
			&-0 {
				margin-bottom: 0 !important;
			}
			&-1 {
				margin-bottom: 0.25rem !important;
			}
			&-2 {
				margin-bottom: 0.5rem !important;
			}
			&-3 {
				margin-bottom: 1rem !important;
			}
			&-4 {
				margin-bottom: 1.5rem !important;
			}
			&-5 {
				margin-bottom: 3rem !important;
			}
		}
		&t,
		&y {
			&-0 {
				margin-top: 0 !important;
			}
			&-1 {
				margin-top: 0.25rem !important;
			}
			&-2 {
				margin-top: 0.5rem !important;
			}
			&-3 {
				margin-top: 1rem !important;
			}
			&-4 {
				margin-top: 1.5rem !important;
			}
			&-5 {
				margin-top: 3rem !important;
			}
		}
		&l,
		&x {
			&-0 {
				margin-left: 0 !important;
			}
			&-1 {
				margin-left: 0.25rem !important;
			}
			&-2 {
				margin-left: 0.5rem !important;
			}
			&-3 {
				margin-left: 1rem !important;
			}
			&-4 {
				margin-left: 1.5rem !important;
			}
			&-5 {
				margin-left: 3rem !important;
			}
		}
		&r,
		&x {
			&-0 {
				margin-right: 0 !important;
			}
			&-1 {
				margin-right: 0.25rem !important;
			}
			&-2 {
				margin-right: 0.5rem !important;
			}
			&-3 {
				margin-right: 1rem !important;
			}
			&-4 {
				margin-right: 1.5rem !important;
			}
			&-5 {
				margin-right: 3rem !important;
			}
		}
	}
	.-m {
		&-1 {
			margin: -0.25rem !important;
		}
		&-2 {
			margin: -0.5rem !important;
		}
		&-3 {
			margin: -1rem !important;
		}
		&-4 {
			margin: -1.5rem !important;
		}
		&-5 {
			margin: -3rem !important;
		}
		&b,
		&y {
			&-1 {
				margin-bottom: -0.25rem !important;
			}
			&-2 {
				margin-bottom: -0.5rem !important;
			}
			&-3 {
				margin-bottom: -1rem !important;
			}
			&-4 {
				margin-bottom: -1.5rem !important;
			}
			&-5 {
				margin-bottom: -3rem !important;
			}
		}
		&t,
		&y {
			&-1 {
				margin-top: -0.25rem !important;
			}
			&-2 {
				margin-top: -0.5rem !important;
			}
			&-3 {
				margin-top: -1rem !important;
			}
			&-4 {
				margin-top: -1.5rem !important;
			}
			&-5 {
				margin-top: -3rem !important;
			}
		}
		&l,
		&x {
			&-1 {
				margin-left: -0.25rem !important;
			}
			&-2 {
				margin-left: -0.5rem !important;
			}
			&-3 {
				margin-left: -1rem !important;
			}
			&-4 {
				margin-left: -1.5rem !important;
			}
			&-5 {
				margin-left: -3rem !important;
			}
		}
		&r,
		&x {
			&-1 {
				margin-right: -0.25rem !important;
			}
			&-2 {
				margin-right: -0.5rem !important;
			}
			&-3 {
				margin-right: -1rem !important;
			}
			&-4 {
				margin-right: -1.5rem !important;
			}
			&-5 {
				margin-right: -3rem !important;
			}
		}
	}

	//PADDINGS
	.p {
		&-0 {
			padding: 0 !important;
		}
		&-1 {
			padding: 0.25rem !important;
		}
		&-2 {
			padding: 0.5rem !important;
		}
		&-3 {
			padding: 1rem !important;
		}
		&-4 {
			padding: 1.5rem !important;
		}
		&-5 {
			padding: 3rem !important;
		}
		&b,
		&y {
			&-0 {
				padding-bottom: 0 !important;
			}
			&-1 {
				padding-bottom: 0.25rem !important;
			}
			&-2 {
				padding-bottom: 0.5rem !important;
			}
			&-3 {
				padding-bottom: 1rem !important;
			}
			&-4 {
				padding-bottom: 1.5rem !important;
			}
			&-5 {
				padding-bottom: 3rem !important;
			}
		}
		&t,
		&y {
			&-0 {
				padding-top: 0 !important;
			}
			&-1 {
				padding-top: 0.25rem !important;
			}
			&-2 {
				padding-top: 0.5rem !important;
			}
			&-3 {
				padding-top: 1rem !important;
			}
			&-4 {
				padding-top: 1.5rem !important;
			}
			&-5 {
				padding-top: 3rem !important;
			}
		}
		&l,
		&x {
			&-0 {
				padding-left: 0 !important;
			}
			&-1 {
				padding-left: 0.25rem !important;
			}
			&-2 {
				padding-left: 0.5rem !important;
			}
			&-3 {
				padding-left: 1rem !important;
			}
			&-4 {
				padding-left: 1.5rem !important;
			}
			&-5 {
				padding-left: 3rem !important;
			}
		}
		&r,
		&x {
			&-0 {
				padding-right: 0 !important;
			}
			&-1 {
				padding-right: 0.25rem !important;
			}
			&-2 {
				padding-right: 0.5rem !important;
			}
			&-3 {
				padding-right: 1rem !important;
			}
			&-4 {
				padding-right: 1.5rem !important;
			}
			&-5 {
				padding-right: 3rem !important;
			}
		}
	}

	//DISPLAYS
	.d {
		&-flex {
			display: flex;
		}
		&-none {
			display: none;
		}
		&-block {
			display: block;
		}
		&-table-cell {
			display: table-cell;
		}
		&-inline {
			display: inline;

			&-block {
				display: inline-block;
			}
		}
	}
	.flex {
		&-col {
			flex-direction: column;
		}
		&-row {
			flex-direction: row;
		}
		&-grow {
			flex-grow: 1;
		}
	}
	.gap {
		&-1 {
			gap: 0.5rem;
		}
		&-2 {
			gap: 1rem;
		}
		&-3 {
			gap: 1.5rem;
		}
		&-4 {
			gap: 2rem;
		}
		&-x {
			&-1 {
				column-gap: 0.5rem;
			}
			&-2 {
				column-gap: 1rem;
			}
			&-3 {
				column-gap: 1.5rem;
			}
			&-4 {
				column-gap: 2rem;
			}
		}
		&-y {
			&-1 {
				row-gap: 0.5rem;
			}
			&-2 {
				row-gap: 1rem;
			}
			&-3 {
				row-gap: 1.5rem;
			}
			&-4 {
				row-gap: 2rem;
			}
		}
	}
	.justify-content {
		&-between {
			justify-content: space-between;
		}
		&-evenly {
			justify-content: space-evenly;
		}
		&-center {
			justify-content: center;
		}
		&-start {
			justify-content: flex-start !important;
		}
		&-end {
			justify-content: flex-end;
		}
	}
	.items {
		&-start {
			align-items: start !important;
		}
		&-baseline {
			align-items: baseline !important;
		}
		&-center {
			align-items: center !important;
		}
		&-end {
			align-items: end !important;
		}
	}
	.whitespace {
		&-normal {
			white-space: normal;
		}
		&-nowrap {
			white-space: nowrap;
		}
		&-pre {
			white-space: pre;
		}
		&-pre-line {
			white-space: pre-line;
		}
		&-pre-wrap {
			white-space: pre-wrap;
		}
	}

	.step-highlight {
		outline: $yellow-light solid 2px;
		outline-offset: 2px;
	}

	.hk-layout {
		min-height: calc(100vh - #{$header-height});
		display: grid;
		grid-template-rows: 1fr max-content;
		grid-template-columns: 100%;

		.hk-ad {
			background-color: $neutral-10;

			.card-body {
				padding: 10px 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				img {
					margin: 0 15px 0 -5px;
					width: 80px;
				}
				.right {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;
					width: 100%;

					.title {
						font-weight: bold;
						font-size: 20px;
					}
				}
			}
		}
	}

	.breadcrumb {
		margin-bottom: 1rem;
		background: $neutral-6;
		border-radius: $border-radius;
		padding: 10px 15px;
	}

	.offline {
		position: fixed;
		top: 50px;
		max-width: 350px;
		background: $red;
		color: $neutral-1;
		z-index: 99;
		text-align: center;
		left: 50%;
		transform: translateX(-50%);
		padding: 0 10px;
		font-size: 12px;
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}

	.saved {
		animation: fadeout 0.3s linear;
		animation-delay: 2s;
	}

	.flip-clock {
		.flip-card {
			&__top,
			&__bottom,
			&__back,
			&__back-bottom {
				color: $blue !important;

				&:before {
					color: $blue !important;
				}
			}
		}
	}

	.disabled {
		cursor: forbidden !important;
		opacity: 0.5 !important;
	}

	.content-count {
		background-color: $neutral-9;
		padding: 5px 10px;
		border-radius: $border-radius;
		user-select: none;
		margin-right: 4px;

		.divider {
			color: $neutral-2;
			margin: 0 4px;
		}
	}

	.invert {
		filter: invert(1);
	}

	.pointer {
		cursor: pointer !important;
	}
	.cursor {
		&-pointer {
			cursor: pointer !important;
		}
		&-default {
			cursor: default !important;
		}
		&-not-allowed {
			cursor: not-allowed !important;
		}
	}

	.truncate-cell {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 1px;
	}
	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: inherit;
	}

	.scroll {
		&::-webkit-scrollbar {
			display: none !important;
		}
	}

	.q-card.install-prompt {
		color: $neutral-10;
		max-width: 300px;
		align-items: top;

		.logo {
			align-self: top;
			width: 50px;
			margin-right: 10px;

			img {
				width: 50px;
			}
		}
		p {
			margin-bottom: 10px;
		}
		.play-store {
			width: 130px;
		}
		h4 {
			margin-bottom: 5px;
			font-size: 18px;
			line-height: 20px;
			font-weight: bold;
		}
		.q-checkbox {
			margin-left: -7px;
		}
	}

	.openSlot {
		display: flex;
		justify-content: space-between;
		padding: 0 10px;
		width: 100%;
		height: 46px;
		line-height: 46px;
		margin-top: 1px;
		border: dashed 1px $neutral-2;

		&.none {
			font-size: 20px;
			display: block;
			text-align: center;
			border-color: transparent;
			background-color: $patreon-red;
		}
	}

	.scrollable-content {
		height: calc(100vh - #{$header-height});

		&::-webkit-scrollbar {
			display: none;
		}
		.content {
			margin: 0 auto;
			width: 100%;
			max-width: 1250px;
			padding: 20px;

			&__edit {
				.save {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;
					position: sticky;
					bottom: 5px;
					padding: 10px 10px;
					margin: 5px 5px;
					background: $neutral-9;
					border: solid 1px $neutral-8;
					border-radius: $border-radius;
					flex-direction: row-reverse;

					.unsaved_changes {
						margin: 0 10px 0 0;
						line-height: 31px;
					}
					.buttons {
						display: flex;
						justify-content: flex-end;
					}
					@media only screen and (max-width: 343px) {
						.buttons {
							margin-bottom: 15px;
						}
					}
				}
			}
		}
	}
	.content-side {
		position: sticky;
		top: 16px;

		.video {
			.animated-video {
				margin-left: -2%;
				transform: scale(1.8);
			}
			.video-controls {
				width: 100%;
				padding: 0 20px;
				display: flex;
				justify-content: space-between;
			}
		}
	}
	.home {
		scroll-behavior: smooth;

		.content {
			max-width: 1140px;
			margin: auto;
		}
		&__container {
			padding: 0 15px;
			width: 100%;
			max-width: 1280px;
			margin-right: auto;
			margin-left: auto;
		}
		section {
			font-size: 18px;
			line-height: 30px;

			.head {
				margin-bottom: 50px;

				h2 {
					font-size: 35px;
					line-height: 45px;
					margin-bottom: 10px;
				}
				.text {
					max-width: 600px;
				}
			}
			h3 {
				font-size: 25px !important;
				margin-bottom: 30px !important;
				font-weight: normal;
			}
			p {
				margin-bottom: 50px;
			}
			.next {
				display: block;
				text-align: center;
				margin: 0;
				color: $neutral-1 !important;
				position: absolute;
				bottom: 70px;
				left: 50%;
				margin-left: -125px;
				width: 250px;
				font-size: 25px;

				i {
					font-size: 35px;
				}

				&:hover {
					text-decoration: none;

					i {
						font-size: 45px;
					}
				}
			}
		}
	}

	.character-description {
		.stat {
			font-weight: bold;
			cursor: default;
		}
		:last-child {
			margin-bottom: 0;
		}
	}

	.validate {
		margin-top: 5px;
		font-size: 11px;
	}
	.error,
	.error:focus {
		border-color: $red !important;
	}

	.loader {
		line-height: 25px;
		height: 25px;
		padding-left: 25px;
		color: $neutral-2;

		&:before {
			content: "";
			background-image: url("../assets/_img/logo/logo-icon-no-shield-cyan.svg");
			background-size: cover;
			box-sizing: border-box;
			position: absolute;
			width: 25px;
			height: 25px;
			margin-left: -30px;
			animation: spinner 1s ease infinite;
		}
		&:after {
			display: inline-block;
			animation: ellipsis steps(1, end) 1s infinite;
			content: "";
		}
	}

	.spin {
		animation: spinner 1s ease infinite;
	}
	.rotate {
		animation: spinner 1s ease;
	}

	.live {
		font-family: "Open Sans", sans-serif;
		user-select: none;
		display: inline-block;
		background-color: $neutral-5;
		padding: 5px 10px;
		font-size: 12px;
		border-radius: $border-radius;
		max-height: 25px;
		line-height: normal;
		text-transform: uppercase;

		&::before {
			content: "";
			background-color: $neutral-1;
			display: inline-block;
			height: 10px;
			width: 10px;
			border-radius: 50%;
			margin-right: 5px;
		}

		&:hover {
			background-color: $neutral-4;
		}
		&.active {
			background-color: $red;

			&::before {
				background-color: $neutral-1;
				animation: fadeinout 1.3s linear infinite;
			}
			&:hover {
				background-color: $red-hover;
			}
		}
		&.disabled {
			opacity: 0.8;
			cursor: not-allowed !important;
		}
	}

	.grow {
		&:hover {
			animation-name: grow;
			animation-duration: 0.5s;
		}
	}

	label.required:after {
		content: "*";
		color: $red;
		font-size: 25px;
		line-height: 15px;
		vertical-align: middle;
		margin-left: 5px;
	}

	/* 4. Animations */
	/*********************************************/
	@keyframes spinit {
		0% {
			top: 0em;
		}
		50% {
			top: -5em;
		}
		100% {
			top: -9em;
		}
	}

	@keyframes grow {
		0% {
			width: 0;
		}
		100% {
			width: 100%;
		}
	}
	@keyframes spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes fadeinout {
		0%,
		100% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
	}
	@keyframes fadeout {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	@keyframes ellipsis {
		0% {
			content: "";
		}
		25% {
			content: ".";
		}
		50% {
			content: "..";
		}
		75% {
			content: "...";
		}
		100% {
			content: "";
		}
	}

	/* 4. Buttons and Links */
	/*********************************************/
	a {
		text-decoration: none;
		color: $blue;
		cursor: pointer;

		&:hover {
			text-decoration: none;
		}
	}

	.btn {
		cursor: pointer;
		border-radius: $border-radius-small;
		background: $blue;
		color: $neutral-1;
		font-weight: 700;
		text-align: center;
		vertical-align: middle;
		user-select: none;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		line-height: 1.5;
		transition:
			color 0.15s ease-in-out,
			background-color 0.15s ease-in-out;

		&:focus-visible {
			outline: $outline;
			box-shadow: none;
		}
		&.disabled {
			cursor: not-allowed;
			opacity: 0.3;
			color: $neutral-1;
		}
		&:hover {
			background-color: $blue-hover;
		}
		&.btn-xs {
			padding: 0.1rem 0.3rem;
			font-size: 0.6rem;
		}
		&.btn-sm {
			padding: 0.25rem 0.5rem;
			font-size: 0.875rem;
		}
		&.btn-lg {
			padding: 0.5rem 1rem;
			font-size: 1.25rem;
		}
		&.btn-block {
			display: block;
			width: 100%;
		}
		&.btn-clear {
			background: none !important;

			&:hover {
				background-color: $neutral-5 !important;
			}
		}
		&.bg-green:hover {
			background-color: $green-hover !important;
		}
		&.bg-red:hover {
			background-color: $red-hover !important;
		}
		&.bg-neutral-4:hover {
			background-color: $neutral-3 !important;
		}
		&.bg-neutral-5:hover {
			background-color: $neutral-4 !important;
		}
		&.bg-neutral-6:hover {
			background-color: $neutral-5 !important;
		}
		&.bg-neutral-7:hover {
			background-color: $neutral-6 !important;
		}
		&.bg-neutral-8:hover {
			background-color: $neutral-7 !important;
		}
		&.bg-neutral-9:hover {
			background-color: $neutral-8 !important;
		}
		&.bg-accent:hover {
			background-color: $accent-light !important;
		}

		&.btn-square {
			border-radius: 0;
		}
	}

	a.logout {
		color: $neutral-2 !important;
		font-size: 20px;
		cursor: pointer;

		&:hover {
			color: $blue !important;
		}
	}

	/* 5. Components*/
	/*********************************************/
	hr {
		margin-top: 1rem;
		margin-bottom: 1rem;
		border: 0;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		background: $neutral-4;
	}
	.container {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}

	.login-container {
		background-position: center;
		padding: 50px 30px 0 30px;
	}
	#login {
		background-color: $neutral-7;
		max-width: 360px;
		margin: auto;
		padding: 120px 30px 30px;
		position: relative;
		box-shadow: 5px 5px 10px #000;
		border-radius: $border-radius;
		overflow: visible;
		width: 100%;

		h1,
		h2 {
			text-transform: none !important;
			text-align: center;
			margin-bottom: 20px;
		}
		img.logo {
			width: calc(100% - 50px);
			margin: 0 25px;
			position: absolute;
			top: -35px;
			left: 0;
		}
		.q-field {
			margin-bottom: 1px;
		}
		.q-form {
			overflow: auto;
		}
	}
	.google {
		background-color: $google-blue;
		font-family: "Roboto", sans-serif;
		height: 40px;
		padding: 0 8px 0 0;
		line-height: 40px;
		text-align: left;
		font-weight: bold;
		width: 100%;
		border-radius: $border-radius-small;
		color: $neutral-1;
		align-items: center;
		cursor: pointer;
		border: solid 1px $google-blue;

		&::before {
			content: "";
			background-image: url("../assets/_img/styles/google.png");
			background-color: $white;
			background-repeat: no-repeat;
			background-position: center;
			height: 38px;
			width: 38px;
			margin-right: 9px;
			display: block;
			float: left;
			background-size: 22px;
			border-top-left-radius: $border-radius-small;
			border-bottom-left-radius: $border-radius-small;
		}
	}

	/* ACCORDION */
	.accordion {
		.q-item {
			background-color: $neutral-9;
			margin-bottom: 1px;

			&__section--avatar {
				min-width: max-content;
			}
		}
		.accordion-body {
			background-color: $neutral-8;
			padding: 20px;

			.hk-table {
				.hk-table-column {
					background-color: $neutral-9;
				}
			}
		}
	}

	/* HK CARD */
	.hk-card {
		position: relative;
		display: flex;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-clip: border-box;
		background-color: $neutral-6;
		margin-bottom: 20px;
		border-radius: $border-radius;
		overflow: auto;

		&::-webkit-scrollbar {
			display: none !important;
		}
		.card-header {
			padding: 15px;
			margin-bottom: 0;
			background-color: $neutral-8;
			border-bottom: 1px solid $neutral-6;
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 62px;

			.btn {
				background-color: $neutral-5;

				&:hover {
					background-color: $neutral-4;
				}
				transition: background-color 0.2s linear;
			}
			h1,
			h2,
			h3 {
				font-size: $font-size;
				font-family: "Open Sans", sans-serif;
				line-height: normal;
				margin: 0;
			}
		}
		.card-body {
			flex: 1 1 auto;
			min-height: 1px;
			padding: 1.25rem;

			&:empty {
				display: none;
			}
		}
		.card-footer {
			padding: 12px 15px;
			background-color: $neutral-8;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
		.card-image {
			position: relative;
			min-height: 180px;
			width: 100%;
			background-position: center;
			background-size: cover;
			box-shadow: inset 0 -5px 25px rgba(0, 0, 0, 1);
			padding: 10px;

			h2,
			.image-title {
				font-size: 20px;
				text-align: center;
				font-family: unset;
				opacity: 0.8;
				text-shadow: 0 0 3px $black;
			}
			.link {
				position: absolute;
				bottom: 10px;
				left: 10px;
				font-size: 12px;
				opacity: 0.5;

				&:hover {
					opacity: 1;
				}
			}
		}

		&.no-margin {
			margin-bottom: 0;
		}
		&.banner {
			flex-direction: row;
			min-height: 242px;

			.card-body {
				flex: none;
				width: 250px;
				max-width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				h3 {
					margin-bottom: 10px;
				}

				.btn {
					align-self: flex-start;
				}
			}
			&-small {
				flex-direction: column;
			}
		}
		&.small {
			font-size: 12px;

			.card-body {
				padding: 1rem;
			}
			.card-header {
				font-size: 15px;
				padding: 0.5rem 1rem;
				min-height: 52px;
			}
		}
	}

	/* HK CARD DECK */
	.hk-card-deck {
		display: flex;
		flex-flow: row wrap;
		margin-right: -15px;
		margin-left: -15px;

		.hk-card {
			flex: 1 1 100%;
			margin-right: 15px;
			margin-bottom: 0;
			margin-left: 15px;
		}
		@media only screen and (max-width: 992px) {
			.hk-card {
				margin-bottom: 25px;
			}
		}
	}

	/* HK ROLLS */
	.hk-roll {
		&.disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	.hk-rolls {
		top: 50px;
		position: fixed;
		display: flex;
		z-index: 9000;
		pointer-events: none;
		width: 100%;

		&.center-top {
			justify-content: center;
		}
		.rolls {
			pointer-events: auto;
			width: 300px;
			padding: 10px;
			max-height: calc(100vh - 50px);
			overflow-y: auto;
			display: flex;
			flex-direction: column-reverse;

			.q-btn {
				pointer-events: auto;
			}

			&::-webkit-scrollbar {
				display: none !important;
			}
		}
	}

	// In character creator -> class -> xp dialog
	.handle-xp {
		display: flex;
		justify-content: center;

		.q-field {
			height: 77px;
			margin-right: 1px;
			max-width: 150px;

			&__control {
				height: 77px;

				input {
					text-align: center;
					font-size: 20px;
				}
			}
		}
	}

	/* HK TABLE */
	.hk-table {
		&-column {
			background: $neutral-8;

			&.hk-table-header {
				background: none;
				padding: 5px 10px !important;
				font-weight: bold;
			}
			.image {
				width: 46px;
				height: 46px;
				display: block;
				background-size: cover;
				background-position: top center;
				border: solid 1px $neutral-2;
				background-color: $neutral-9;
				color: $neutral-2;
				font-size: 34px;

				i::before {
					vertical-align: 2px;
				}
			}
			.actions {
				display: flex;
				justify-content: flex-end;
				padding: 9px 12px;
				user-select: none;
			}
			.q-field {
				width: 60px;

				.row {
					padding: 0;
				}
				&__control {
					height: 26px;
				}
				input {
					text-align: center;
					height: 26px;
					line-height: 26px;
					padding: 0 5px;
				}
			}
		}
		.hk-collapsed-column {
			background: $neutral-9;
		}
	}

	.drawer {
		&-wrapper {
			position: fixed;
			right: 0;
			top: 50px;
			height: calc(100vh - 50px);
			width: 420px;
			max-width: 95%;
			z-index: 99;
			box-shadow: 0 10px 15px $black;
			background: $neutral-8;
		}
		height: 100%;
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: 40px auto;
		grid-template-areas:
			"hide"
			"content";

		.q-scrollarea {
			height: 100%;
			grid-area: content;
		}

		a.hide {
			grid-area: hide;
			padding-left: 10px;
			width: 100%;
			font-size: 15px;
			line-height: 40px;
			position: absolute;
			right: 0px;
			top: 0px;
			z-index: 1000;
			background: $neutral-9;
			color: $neutral-1 !important;
		}
		&__content {
			padding: 15px;
			overflow: auto;

			&.monster-card {
				background-color: #f7e4ba !important;
				background-image: url("../assets/_img/styles/monster-card-bg.png") !important;
			}
			&::-webkit-scrollbar {
				display: none !important;
			}
		}

		.hk-table {
			.hk-table-column,
			.hk-collapsed-column {
				background: $neutral-9;

				&.hk-table-header {
					background: none;
				}
			}
		}
		.target-item {
			.ac_wrapper {
				background-color: $neutral-5;
			}
		}
		.q-item {
			background-color: $neutral-9 !important;
		}
		.q-field__control {
			.q-item {
				background: none !important;
			}
		}
		ul.entities {
			li {
				background-color: $neutral-8;
				border-radius: $border-radius;
				overflow: hidden;

				&:hover {
					background-color: $neutral-7;
				}
				transition: background-color 0.2s linear;
			}
		}
		table,
		.table {
			tr {
				td {
					background-color: $neutral-8;
				}
			}
		}
	}

	table.table,
	table {
		border-collapse: separate !important;
		border-spacing: 0 1px;
		width: 100%;
		margin-bottom: 20px;

		p {
			margin: 0;
		}

		thead {
			margin-bottom: 1px !important;

			th {
				text-align: left !important;
				padding: 10px;
				border: none;
			}
		}
		tbody {
			tr {
				margin-bottom: 1px !important;

				th {
					text-align: left;
					padding: 10px;
					border: none;
				}

				td {
					width: max-content;
					padding: 10px;
					border-top: none;
					background-color: $neutral-8;
					position: relative;

					.actions {
						padding: 5px 0.75rem;
					}

					.actions-dropdown {
						width: 30px;
						text-align: center;
						a {
							display: block;
						}
					}
					.fa-ellipsis-v {
						line-height: 22px;
					}
					span.bind {
						border: solid 1px #2c97de;
						display: inline-block;
						min-width: 20px;
						padding: 0 5px;
						text-align: center;
					}
					&.actions {
						router-link,
						a,
						.disabled {
							font-size: 15px;
							line-height: 0px;
						}
						.disabled {
							opacity: 0.3;
						}
					}

					&.index {
						width: 1% !important;
						white-space: nowrap;
					}
					&.img {
						padding: 3px;
						width: 46px;
						height: 46px;
						background-size: cover;
						background-position: top center;
						background-color: $black;
						border: solid 1px $neutral-3 !important;

						img {
							width: 40px;
							height: 40px;
						}
					}
				}

				&:focus {
					td {
						background-color: $neutral-9;
					}
					.actions {
						display: flex !important;
					}
				}
				&.openSlot {
					td {
						background: none;
						border: dashed 1px $neutral-4 !important;

						.slot {
							display: flex;
							justify-content: space-between;
						}
					}
				}
			}
		}
		&.table-sm {
			font-size: 13px;
			td,
			th {
				padding: 5px;
			}
		}
	}

	/* HK POPOVER */
	.hk-popover {
		user-select: none;
		cursor: pointer;

		&:hover {
			color: $blue;
		}
	}

	.target-item-wrapper {
		border-radius: $border-radius-small;
		overflow: hidden;
		width: 100%;
	}
	.target-item {
		display: grid;
		grid-template-columns: 40px 40px 1fr;
		grid-template-rows: auto;
		grid-gap: 1px;
		line-height: 40px;
		user-select: none;
		border-radius: $border-radius-small;
		width: 100%;

		&.hasInitiative {
			grid-template-columns: 40px 40px 40px 1fr;
		}
		.initiative,
		.ac,
		.target-avatar {
			text-align: center;
			height: 40px;
			width: 40px;
		}
		.initiative {
			padding: 0 5px;
			font-weight: bold;
		}
		.ac_wrapper {
			position: relative;
			cursor: pointer;
			color: $neutral-4;

			i,
			.ac {
				position: absolute;
				line-height: 40px;
				width: 100%;
				text-align: center;
			}
			i {
				font-size: 33px;
				margin-top: 1px;
			}
			.ac {
				font-weight: bold;
				filter: invert(1) grayscale(1) brightness(1.3) contrast(9000);
				mix-blend-mode: luminosity;
				opacity: 0.95;
			}
		}
		.target-avatar {
			width: 40px;
			height: 40px;
			background-color: $neutral-9;
			font-size: 20px;
			line-height: 40px;
			overflow: hidden;

			[class^="hki-"],
			[class*=" hki-"] {
				font-size: 28px;
				margin-left: -1px;
			}

			span {
				position: relative;
			}
		}

		.q-linear-progress {
			.health-bar {
				padding: 0 10px;
				font-size: 13px;
				display: grid;
				grid-template-columns: 1fr max-content;
				grid-column-gap: 5px;
				line-height: 40px;
				color: $neutral-1;

				.conditions {
					position: absolute;
					right: 0;
					top: 0;

					.condition {
						display: block;
						width: 12px;
						height: 12px;
						border: solid 1px $neutral-1;
						margin-left: 1px;
						cursor: pointer;
					}
				}

				.hp {
					.health,
					.temp {
						text-align: right;
						cursor: pointer;
						border-radius: $border-radius-small;
						font-size: 15px;

						.current {
							font-weight: bold;
						}
						.max {
							opacity: 0.8;
						}
					}
					.save {
						margin-right: 4px;
					}
				}
			}
		}
	}

	.currency {
		margin: auto;
		display: flex;
		justify-content: center;
		max-width: 400px;
		text-align: center;

		img {
			height: 25px;
			margin-bottom: 10px;
		}
		div {
			margin-right: 5px;

			&:last-child {
				margin-right: 0;
			}
		}
		.q-field {
			.row {
				text-align: center;
				padding: 0 5px;
			}
			input {
				text-align: center;
			}
			&__label {
				font-size: 10px;
			}
		}
	}

	ul.settings {
		border-top: solid 1px $neutral-5;
		padding: 0 !important;
		list-style: none;
		margin-bottom: 60px;

		li {
			font-size: 13px;
			padding: 15px 5px;
			border-bottom: solid 1px $neutral-5;

			.btn {
				color: $neutral-2 !important;
			}
		}
	}

	ul.entities {
		list-style: none;
		padding: 0;

		li {
			display: grid;
			background-color: $neutral-7;
			grid-template-columns: auto max-content;
			margin-bottom: 1px;
			vertical-align: center;
			line-height: 46px;
			padding: 0 10px;

			.actions {
				display: flex;
				justify-content: flex-end;
				background: none !important;
				padding-right: 10px;

				a {
					color: $neutral-2 !important;
					margin-left: 10px;

					&:hover {
						text-decoration: none;
					}
				}
			}
			&:hover {
				background-color: $neutral-9;

				.hover-hide {
					display: none;
				}
			}
			.fa-ellipsis-v {
				line-height: 22px !important;
			}
			.img {
				width: 46px;
				height: 46px;
				display: block;
				background-size: cover;
				background-position: top center;
				border: solid 1px $neutral-3;
				background-color: $neutral-10;
				font-size: 35px;
				line-height: 44px;
				text-align: center;
				color: $neutral-2;
			}
		}
		&.hasImg {
			li {
				padding-left: 0;
				grid-template-columns: 55px auto max-content;

				.overview-item {
					display: grid;
					grid-template-columns: auto 20px;
					grid-gap: 5px;
				}
			}
		}
	}

	// Q-INPUT FIELDS
	.manual-input {
		height: 90px;
		font-size: 50px;

		.q-field {
			height: 90px;

			&__control {
				height: 90px;

				input {
					text-align: center;
				}
			}
		}
	}

	.player-initiative {
		.q-field__control {
			padding: 0;

			input {
				padding-left: 12px;
			}
		}
	}

	//VIEW MONSTER
	.q-table {
		border-spacing: 0 1px;

		.q-field {
			width: 60px;

			.row {
				padding: 0;
			}
			&__control {
				height: 26px;
			}
			input {
				text-align: center;
				height: 26px;
				line-height: 26px;
				padding: 0 5px;
			}
		}
		.q-td {
			background-color: $neutral-7;

			&.avatar {
				padding: 0;
				width: 55px;
				height: 55px;
				border: solid 1px $neutral-4;
				background-size: cover;
				background-position: center top;
				background-color: $neutral-8;
				color: $neutral-2;
				text-align: center;
				font-size: 45px;
				line-height: 55px;
				box-sizing: border-box;
			}
		}

		.monster {
			column-count: 2;

			.skills {
				column-count: 2;
			}
		}
		.monster-card {
			padding: 10px;
		}
	}
	.monster-card {
		background-color: #f7e4ba !important;
		background-image: url("../assets/_img/styles/monster-card-bg.png") !important;
	}
	@media only screen and (max-width: 1530px) {
		.monster-table {
			.hk-table {
				.hk-collapsed-column {
					.monster {
						column-count: 1;
					}
				}
			}
		}
		.q-table {
			.monster {
				column-count: 1;
			}
		}
	}

	.slideInRight {
		animation-duration: 0.5s;
	}
	.slideOutRight {
		animation-duration: 0.5s;
	}

	@media only screen and (max-width: 575px) {
		table tr td .actions {
			display: none !important;
			background-color: $neutral-9;
			right: 20px;
			a {
				background: $neutral-3;
			}
		}
	}

	/* 7. Snotify overrides */
	/*********************************************/
	.snotifyToast {
		border-radius: $border-radius !important;
	}
	.snotify-leftTop,
	.snotify-centerTop,
	.snotify-rightTop {
		top: 60px !important;
	}

	.snotifyToast__title {
		font-weight: normal;
		text-transform: uppercase;
		font-size: 15px !important;
	}
	.snotifyToast__body {
		font-size: 12px !important;
	}
	.snotifyToast__buttons {
		border-top: 2px solid rgba(0, 0, 0, 0.1);

		button {
			cursor: pointer;
		}
	}
	.snotify-simple {
		border: solid 1px $neutral-5;
		user-select: none;

		.snotifyToast__inner {
			padding: 13px 15px 15px 15px !important;
			background-color: $neutral-8 !important;

			.snotifyToast__title {
				color: $neutral-2 !important;
				width: 100%;

				.target {
					display: grid;
					grid-template-columns: 30px 30px 1fr;
					font-size: 15px;
					line-height: 30px;
					margin-bottom: 10px;
					text-transform: none !important;
					background-color: $neutral-10;
					position: relative;

					.ac {
						text-align: center;
						background-color: $neutral-10;
					}
					.image {
						width: 30px;
						height: 30px;
						background-size: cover;
						background-position: center top;
						background-color: $neutral-10;
					}
					.name {
						padding: 0 10px;
					}
					&:hover {
						.name {
							direction: rtl;
						}
					}
				}
			}
			.snotifyToast__body {
				color: $neutral-2 !important;
				width: 100%;

				h2 {
					text-transform: none !important;
					margin-bottom: 5px !important;
				}
				small {
					display: inline-block;
					line-height: 12px !important;
				}
				.title {
					margin-bottom: 15px !important;
				}
				.display-rolls {
					display: flex;
					justify-content: center;
					width: 100%;
					margin-bottom: 10px;

					div.roll {
						border: solid 1px $neutral-3;
						text-align: center;
						padding: 5px 0;
						flex-direction: column;
						flex: 1 1 50%;
						margin: 5px;
						position: relative;

						h2 {
							font-size: 40px;
							margin-bottom: 0 !important;
							height: 50px;
							line-height: 50px;
							font-weight: bold;
						}
						.top,
						.bottom {
							font-size: 15px;
							height: 20px;
							line-height: 20px;
						}
						.advantage {
							position: absolute;
							background: $neutral-10;
							padding: 0 10px;
							top: -5px;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
				&.roll {
					width: calc(100% + 30px);
					margin: -13px -15px -15px -15px;

					.roll_title {
						background: $neutral-8;
						font-size: 15px;
						padding: 10px;
						text-align: center;
					}
					.rolled {
						line-height: 130px;
						width: 100%;
						text-align: center;
						font-size: 80px;
						font-weight: bold;
						background-color: $neutral-7;
					}
					.roll_footer {
						text-align: center;
						padding: 10px;
						font-size: 15px;
						background-color: $neutral-8;
					}
				}
			}
		}
		.snotifyToast__buttons {
			background: $neutral-9 !important;
		}
	}
	.snotify-error {
		.snotifyToast__inner {
			background-color: $red !important;
		}
		.snotifyToast__buttons {
			background-color: $red !important;
		}
	}
	.snotify-warning {
		.snotifyToast__inner {
			background-color: $orange !important;
		}
		.snotifyToast__buttons {
			background-color: $orange !important;
		}
	}
	.snotify-icon.snotify-icon--error {
		background-image: url("../assets/_img/styles/sword-break.png");
		background-size: cover;
	}
	.snotify-success {
		.snotifyToast__inner {
			background-color: $green !important;
		}
		.snotifyToast__buttons {
			background-color: $green !important;
		}
	}
	.snotify-icon.snotify-icon--success {
		background-image: url("../assets/_img/styles/d20-white.png");
		background-size: cover;
	}

	/* 9. Number field */
	/*********************************************/

	// Remove arrows from number field
	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	/* 10. CROPPA */
	/*********************************************/
	.croppa-container {
		canvas {
			border: solid 1px $neutral-2;
			background-color: $neutral-8 !important;
		}
	}

	/* 11. QUASAR */
	/*********************************************/
	.q-pagination {
		justify-content: center;

		.q-btn {
			background-color: $neutral-8;
			margin-left: 1px;
			color: $neutral-2 !important;
		}
	}
	.q-btn {
		border-radius: $border-radius;
		font-weight: bold;

		&:focus-visible {
			outline: $outline !important;
		}
	}

	.q-btn-group {
		.q-btn {
			border: solid 1px transparent;

			&:focus-visible {
				outline: none !important;
				border-color: $neutral-2;
			}
		}
	}

	.q-menu {
		.q-list {
			.q-item {
				&__section--avatar {
					min-width: 35px;
				}
			}
		}

		table {
			td {
				background-color: $neutral-9 !important;
			}
		}
	}
	.q-list {
		.q-expansion-item {
			.q-item__section {
				&.small-toggle {
					padding-right: 10px;
				}
				&.hidden-toggle {
					display: none !important;
				}
			}
		}
	}

	.q-dialog__inner {
		.hk-card {
			.card-header {
				position: sticky;
				top: 0;
				z-index: 1;
			}
		}
	}

	.sticky-header-table {
		max-height: 300px;

		.q-table__top,
		.q-table__bottom,
		thead tr:first-child th {
			background-color: $neutral-6;
		}

		thead tr th {
			position: sticky;
			z-index: 1;
		}
		thead tr:last-child th {
			top: 48px;
		}
		thead tr:first-child th {
			top: 0;
		}
	}

	.q-tabs--dense {
		.q-tab {
			min-height: 35px;

			&__icon {
				font-size: 15px;
			}
		}
		.q-tabs__arrow {
			line-height: 35px;
		}
	}
	.q-tabs__arrow {
		line-height: 48px;
		font-size: 30px;
		text-shadow: 0px 0px 3px #000;
		color: $neutral-1;
	}

	.q-notification {
		border-radius: $border-radius;
	}
	.q-notifications__list--top {
		top: 120px;
	}

	//RESPONSIVENESS
	/*-----------------------------------*/

	//XS
	@media only screen and (max-width: $sm-breakpoint) {
		.d-xs {
			&-flex {
				display: flex !important;
			}
			&-none {
				display: none !important;
			}
			&-block {
				display: block !important;
			}
			&-table-cell {
				display: table-cell !important;
			}
			&-inline {
				display: inline !important;

				&-block {
					display: inline-block !important;
				}
			}
		}
		.hk-layout {
			padding-top: 38px;

			.breadcrumb {
				margin-bottom: 0;
				background: $neutral-6;
				border-radius: 0;
				border: none;
				padding: 10px 15px;
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				z-index: 1;
				font-size: 13px;
				line-height: normal;

				.q-breadcrumbs {
					> div {
						flex-wrap: nowrap;
						min-width: 0;
						white-space: nowrap;

						:not(:first-child):not(:last-child) {
							&.flex {
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}
				}
			}
		}
		.hasSide {
			grid-template-columns: auto;
		}
		.hk-card {
			&.banner {
				flex-direction: column;

				.card-body {
					width: unset;
					max-width: unset;
				}
			}
		}
	}

	//SM
	@media only screen and (min-width: $sm-breakpoint) {
		.container {
			max-width: 540px;
		}
		.d-sm {
			&-flex {
				display: flex !important;
			}
			&-none {
				display: none !important;
			}
			&-block {
				display: block !important;
			}
			&-table-cell {
				display: table-cell !important;
			}
			&-inline {
				display: inline !important;

				&-block {
					display: inline-block !important;
				}
			}
		}
	}

	//MD
	@media only screen and (min-width: $md-breakpoint) {
		.container {
			max-width: 720px;
		}
		.d-md {
			&-flex {
				display: flex !important;
			}
			&-none {
				display: none !important;
			}
			&-block {
				display: block !important;
			}
			&-table-cell {
				display: table-cell !important;
			}
			&-inline {
				display: inline !important;

				&-block {
					display: inline-block !important;
				}
			}
		}
	}

	//LG
	@media only screen and (min-width: $lg-breakpoint) {
		.container {
			max-width: 960px;
		}
		.d-lg {
			&-flex {
				display: flex !important;
			}
			&-none {
				display: none !important;
			}
			&-block {
				display: block !important;
			}
			&-table-cell {
				display: table-cell !important;
			}
			&-inline {
				display: inline !important;

				&-block {
					display: inline-block !important;
				}
			}
		}
		.hk-card-deck {
			.hk-card {
				flex: 1 0 0%;
				margin-bottom: 1rem;
			}
		}
	}

	//XL
	@media only screen and (min-width: $xl-breakpoint) {
		.container {
			max-width: 1280px;
		}
		.drawer {
			width: 420px;
		}
		.d-xl {
			&-flex {
				display: flex !important;
			}
			&-none {
				display: none !important;
			}
			&-block {
				display: block !important;
			}
			&-inline {
				display: inline !important;

				&-block {
					display: inline-block !important;
				}
			}
		}
	}
}
